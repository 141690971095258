import React from 'react';
import styles from './RawOrderNow.module.css';
import Radium from 'radium';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import ReactHtmlParser, { htmlparser2 } from 'react-html-parser';
import OrderNowButton from '../landingPage/OrderNowButton';

class RawOrderNow extends React.Component {




    render() {


        return (

            <div className={styles.Container} style={{ marginTop: this.props.MarginTop }} >

                <div className={styles.Image1}><img src={this.props.Image1Src} /></div>
                <div className={styles.LogoAndBtContainer}>
                    <div className={styles.LogoAndBt}>
                        <img src={this.props.LogoAndKitSrc} style={{ width: this.props.LogoWidth }} />
                        <OrderNowButton LinkTo={this.props.OrderNowLink} ButtonText={this.props.ButtonText} />
                        <div className={styles.FreeShipping}>Free Shipping!</div>
                        <div className={styles.PriceUnderButton}>
                            {'For only $' + this.props.Price}
                            <span className={styles.price_line + " price_line"}>{this.props.PriceLine}</span>

                        </div>
                        {/* <div className={styles.PriceUnderButton}><span className={this.props.Price + " price_line"}>{this.props.PriceLine}</span> {'$' + this.props.Price + ' + Shipping'}</div> */}
                    </div>
                </div>
                <div className={styles.Image2}><img src={this.props.Image2Src} /></div>
            </div>


        );
    }
}

export default Radium(RawOrderNow);