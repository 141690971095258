import React from 'react';
import styles from './PlayMachinesSet.module.css';
import Radium from 'radium';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import ReactHtmlParser, { htmlparser2 } from 'react-html-parser';

class PlayMachinesSet extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentSlide: 0,
            flexDirection1: 'column-reverse',
            flexDirection2: 'column'

        };
    }

    componentDidMount() {

    }


    next = () => {
        this.setState((state) => ({
            currentSlide: state.currentSlide + 1,
        }));
    };

    prev = () => {
        this.setState((state) => ({
            currentSlide: state.currentSlide - 1,
        }));
    };

    updateCurrentSlide = (index) => {
        const { currentSlide } = this.state;

        if (currentSlide !== index) {
            this.setState({
                currentSlide: index,
            });
        }
    };

    render() {






        return (

            <div className={styles.Container} >
                <div className={styles.Text}><div className={styles.TextContainer}>{this.props.Text}</div></div>
                <div className={styles.Image}><img src={this.props.ImageSrc} /></div>
            </div>


        );
    }
}

export default Radium(PlayMachinesSet);