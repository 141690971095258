import React, { Component, Suspense } from 'react';
import styles from './Packages.module.css';

import Vimeo from '@u-wave/react-vimeo';
import ReactPlayer from 'react-player'

import { withRouter, Link } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { inject, observer } from 'mobx-react';



@inject('SiteLinks')
@observer // REMOVE BRACKETS => @observer
class Package extends React.Component {
    render() {

        // const player = new Plyr('#player', {
        //     debug: true,
        //     volume: 0,
        //     autoplay: true,
        //     muted: true
        // });

        return (
            <Link className={styles.PackageLink} to={this.props.Link}>



                <div className={styles.Packagecontainer}>
                    <div className={styles.TopGreen}>
                        <div className={styles.TopGreenTitle}>{this.props.Title}</div>
                        <img src={this.props.SiteLinks.LegoGreen} />
                    </div>
                    <div className={styles.Price}>
                        {this.props.Price}
                        <span className={styles.PriceLine + ' price_line'}>{this.props.PriceLine}</span>
                        , free shipping
                        </div>
                    <div className={styles.PackageInfo}>
                        <img className={styles.Kit} src={this.props.SiteLinks.Kit} />
                    </div>

                    <div className={styles.InfoItemContainer}>
                        <div className={styles.Icon}><img src={this.props.SiteLinks.VideoIcon} /></div>
                        <div className={styles.info}>{this.props.Info1}</div>
                    </div>

                    <div className={styles.InfoItemContainer}>
                        <div className={styles.Icon}><img src={this.props.SiteLinks.ExploreIcon} /></div>
                        <div className={styles.info}>{this.props.Info2}</div>
                    </div>

                    <div className={styles.InfoItemContainer}>
                        <div className={styles.Icon}><img src={this.props.SiteLinks.PlayIcon} /></div>
                        <div className={styles.info}>{this.props.Info3}</div>
                    </div>
                    <div className={styles.ProgramLogos}>
                        <img src={this.props.SiteLinks.Plm1Logo} />
                        {this.props.Plm2 ?
                            <>
                                <span className={styles.Plus}> + </span>
                                <img src={this.props.SiteLinks.Plm2Logo} />
                            </>
                            : null}
                    </div>
                    <div className={styles.LinkContainer}> <Link className={styles.PackageLink} to={this.props.Link}>View Package</Link></div>
                </div>
            </Link >
        )
    }
}



@inject('SiteLinks')
@observer // REMOVE BRACKETS => @observer
class Packages extends Component {

    constructor(props) {
        super();

        this.state = {
            Data: [],
            ShopData: [],
            IsShopData: false,
            loading: false,
            StoreLoading: false,
            paused: ""
        }
    }


    VimeoEnd = () => {
        this.setState({ paused: true })
        this.setState({ paused: false })
    }
    VimeoPlay = () => {
        this.setState({ paused: false })
    }



    render() {






        return (


            <div className={styles.PageConatiner} >

                <div className={styles.BackgroundContainer}></div>
                <div className={styles.BackgroundImage}><img src={this.props.SiteLinks.Plm2BackgroungDesktop} /></div>
                {/* <div className={styles.BackgroundImageMobile}><img src={this.props.SiteLinks.Plm2BackgroungMobile} /></div> */}
                <div className={styles.VideoInBackgraound}>
                    <Vimeo
                        video={'https://player.vimeo.com/video/435525733'}
                        responsive={true}
                        controls={false}
                        muted={true}
                        loop={true}
                        autopause={false}
                        autoplay={true}
                        className={styles.vimeo_iframe}
                        onPause={this.VimeoEnd}
                        onPlay={this.VimeoPlay}
                        paused={this.state.paused}
                    />
                </div>

                <div className={styles.ContentContainer}>
                    <div className={styles.BackgroundContainer}></div>
                    <Package
                        Title="10 Activities"
                        Info1="10 x Recorded lessons"
                        Info2="10 x Experimental machines"
                        Info3="10 x Play machines"
                        Link='/play-machines-1'
                        Price='$79'
                        PriceLine="$95"
                    />
                    <Package
                        Title="20 Activities"
                        Info1="20 x Recorded lessons"
                        Info2="20 x Experimental machines"
                        Info3="20 x Play machines"
                        Plm2={true}
                        Link='/play-machines-1and2'
                        Price='$99'
                        PriceLine="$119"
                    />

                </div>

            </div >
        );


    }
}

export default withLastLocation(withRouter(Packages));
