import React from 'react';
import styles from './LogoAndCarousel.module.css';
import Radium from 'radium';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import ReactHtmlParser, { htmlparser2 } from 'react-html-parser';

class LogoAndCarousel extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentSlide: 0,
            flexDirection1: 'column-reverse',
            flexDirection2: 'column',
            haveImages: false

        };
    }

    componentDidMount() {

    }


    next = () => {
        this.setState((state) => ({
            currentSlide: state.currentSlide + 1,
        }));
    };

    prev = () => {
        this.setState((state) => ({
            currentSlide: state.currentSlide - 1,
        }));
    };

    updateCurrentSlide = (index) => {
        const { currentSlide } = this.state;

        if (currentSlide !== index) {
            this.setState({
                currentSlide: index,
            });
        }
    };

    render() {



        const Images = this.props.GalleryImages.map((image, key) =>
            <div key={key}>
                <img src={image} />
            </div>
        );
        if (this.props.GalleryImages.length !== 0) {
            if (this.state.haveImages == false) {
                this.setState({ haveImages: true })
            }
        }

        const containerStyle = {


            '@media screen and (max-width:767px)': {
                flexDirection: this.props.LogoFromLeft ? this.state.flexDirection2 : this.state.flexDirection1,
            }
        }
        const Logo = <div className={styles.LogoContainer}><img src={this.props.LogoSrc} /></div>
        return (

            <div className={styles.Container} style={containerStyle}>
                {this.props.LogoFromLeft ? Logo : null}

                <div className={styles.CarouselContainer}>

                    {this.state.haveImages ?
                        <Carousel
                            showThumbs={false}
                            showIndicators={false}
                            infiniteLoop={true}
                            selectedItem={this.state.currentSlide}
                            showArrows={true}
                            onChange={this.updateCurrentSlide}
                            showStatus={true}
                            autoPlay
                            {...this.props}
                        >
                            {Images}
                        </Carousel>
                        : null
                    }

                </div>
                {this.props.LogoFromLeft ? null : Logo}


            </div>


        );
    }
}

export default Radium(LogoAndCarousel);