import React from 'react';
import styles from './VideoAndGallery.module.css';
import '../../screens/LandingPage.css';
import { Carousel } from 'react-responsive-carousel';
import Vimeo from '@u-wave/react-vimeo';

import { Parallax, Background } from 'react-parallax';

class TowVideoAndText extends React.Component {


    constructor() {
        super()
        this.state = {
            isOpen: false,
            haveImages: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        const Images = this.props.GalleryImages.map((image, key) =>
            <div key={key}>
                <img src={image} />
            </div>
        );
        if (this.props.GalleryImages.length !== 0) {
            if (this.state.haveImages == false) {
                this.setState({ haveImages: true })
            }
        }
        return (
            <>

                <div className={styles.VideosContainer}>

                    <div className={styles.Item1}>
                        <Vimeo video={this.props.VideoId} autoplay={false} responsive={true} />
                    </div>
                    <div className={styles.Item2}>
                        {this.state.haveImages ?
                            <Carousel
                                showThumbs={false}
                                showIndicators={false}
                                infiniteLoop={true}
                                selectedItem={this.state.currentSlide}
                                showArrows={true}
                                onChange={this.updateCurrentSlide}
                                showStatus={true}
                                autoPlay
                                {...this.props}
                            >
                                {Images}
                            </Carousel>
                            : null
                        }
                    </div>


                </div>
            </>
        );
    }
}

export default TowVideoAndText;