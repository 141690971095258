
import React from 'react';
import styles from '../../screens/Customer/SelectModelContent.module.css';
import Modelitem from '../Customer/Modelitem';
import { getUserName } from "../../components/functions";

import { Redirect, Link } from 'react-router-dom'

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';



@inject('LoginDetails', 'SiteLinks', 'Programs', 'Lessons')
class SelectModelContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ManuIsOpen: false,
        }
    }

    componentDidMount() {
        if (this.props.ProgramName === 'play-machines-1') {
            this.props.Lessons.UpdatePlm1()
        }
        if (this.props.ProgramName === 'play-machines-2') {
            this.props.Lessons.UpdatePlm2()
        }

    }




    ModelsLoop() {
        // console.log(toJS(this.props.Lessons.Plm1))
        let lessons = []
      
        if (this.props.ProgramName === 'play-machines-1') {

            lessons = this.props.Lessons.Plm1.map((lesson) =>
                <Modelitem
                    key={lesson.id}
                    Title={lesson.title.rendered}
                    TitleBackColor={lesson.single_model_lego_color}
                    ModelPic={lesson.small_image}
                    Number={lesson.metadata.order}
                    programName={lesson.program_name}
                    LessonsId={lesson.id}
                    BottomColor1= '#91B508'
                    BottomColor2='#005898'
                />
            );
            return lessons
        }


        if (this.props.ProgramName === 'play-machines-2') {
            lessons = this.props.Lessons.Plm2.map((lesson) =>
                <Modelitem
                    key={lesson.id}
                    Title={lesson.title.rendered}
                    TitleBackColor={lesson.single_model_lego_color}
                    ModelPic={lesson.small_image}
                    Number={lesson.metadata.order}
                    programName={lesson.program_name}
                    LessonsId={lesson.id}
                    BottomColor1='#FF8100'
                    BottomColor2='#004374'
                />
            );
            return lessons
        }



    }


    render() {

        if (this.props.ProgramName === 'play-machines-2' && !this.props.LoginDetails.HavePlm2()) {
            return <Redirect push to={`/select-program`} />
        }


        return (
            <div className={styles.FlexContainer}>


                <div className={styles.ItemsContainer}>

                    {this.ModelsLoop()}



                </div>


            </div>
        );
    }
}

export default SelectModelContent;