import React, { Component } from 'react';
import styles from './SelectProgram.module.css';

import axios from 'axios';
import { isLoggedIn, isFranchiseOwner, isAdministrator } from "../../components/functions";


import NavbarCustomer from "../../components/Navbars/NavbarCustomer";
import NavbarAdmin from "../../components/Navbars/NavbarAdmin";

import FromChildDetails from '../../components/Customer/FromChildDetails';
import SelectProgramGrid from '../../components/Customer/SelectProgramGrid';
import UpgradePopUp from '../../components/Customer/UpgradePopUp';


import { useMediaQuery } from 'react-responsive';

import Radium from 'radium';
import Loader from "../../loader.gif";


import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';



const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isDesktop = useMediaQuery({ maxWidth: 767 })
    return isDesktop ? children : null
}


@inject('LoginDetails', 'SiteLinks')
@observer // REMOVE BRACKETS => @observer
class SelectProgram extends Component {

    constructor(props) {
        super(props);

        this.state = {
            HaveChildName: true,
            Title: 'Select Your Program',
        }
    }

    componentDidMount() {
        // VIMEO API
        // let Vimeo = require('vimeo').Vimeo;
        // const client_id = "dbfcb42ae496209b3a007f6bc52f761a310f4def"
        // const client_secret = "tpo4rAdOrn1sd/dM2jyyLEU+0JtPb8aNckxhnP7U/YKHJCj9rQ8wM5ATrzhJJJ6IxiPZoJrR9KcEhUnSlwZtJE+wwDupGuzo9ueL3HS/yUUTHabASU60K6NZVePHrKFd"
        // const access_token = "b5642c2898b8a355034123ceb8b11d17"

        // let client = new Vimeo(client_id, client_secret, access_token);

        // client.request({
        //     method: 'GET',
        //     path: 'https://vimeo.com/api/oembed.json?url=ttps%3A//vimeo.com/video/435526343"',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     }
        // }, function (error, body, status_code, headers) {
        //     if (error) {
        //         console.log('ERR' + error);
        //     }

        //     console.log(body);
        // })

      
        this.props.LoginDetails.UpdateUserMeta()

        const ChildName = localStorage.getItem('child_name');


        setInterval(() => {
            if (ChildName == '') {
                this.setState({ HaveChildName: false });
                this.setState({ Title: '' });
            }
        }, 4000);

      
    }

    render() {




        const EnterChildName = (
            <FromChildDetails />
        )


        let SelectProgram = (
            <div className={styles.SelectProgramGrid}>
                <SelectProgramGrid HavePlm1={this.props.LoginDetails.HavePlm1()} HavePlm2={this.props.LoginDetails.HavePlm2()} />
            </div>
        )

        if (this.props.LoginDetails.isAdmin()) {
            SelectProgram = (
                <div className={styles.SelectProgramGrid}>
                    <SelectProgramGrid HavePlm1={true} HavePlm2={true} />
                </div>
            )
        }


        let NavBar = <NavbarCustomer Position="60px" Title={this.state.Title} />
        this.props.LoginDetails.isAdmin() ? NavBar = <NavbarAdmin Position="60px" Title="ADMIN" /> : null


        return (
         

            !isLoggedIn() ? (

                window.location.href = '/'

            ) :

                <React.Fragment>
                    <UpgradePopUp />

                    {NavBar}

                    <div className={styles.PageConatiner}>

                        <Desktop> <div className={styles.BackgroungImg}><img src={this.props.SiteLinks.SelectProgramBack} alt="young engineers"></img></div> </Desktop>
                        <Mobile> <div className={styles.MobileBackgroungImg}><img src={this.props.SiteLinks.SelectProgramBackMobile} alt="young engineers"></img></div> </Mobile>
                        <div style={{ height: '125px' }}></div>


                        {this.state.HaveChildName ? SelectProgram : EnterChildName}

                        {this.props.LoginDetails.loading && <img className="loader" src={Loader} alt="Loader" />}

                    </div>
                </React.Fragment>
        );


    }
}

export default Radium(SelectProgram);
