import usersList from './UsersList'
import pages from './Pages'
import landingPage from './LandingPage'
import woo from './woo'
import LoginDetails from './LoginDetails'
import SiteLinks from './SiteLinks'
import Programs from './Programs'
import Lessons from './Lessons'

const stores = {
    usersList,
    pages,
    landingPage,
    woo,
    LoginDetails,
    SiteLinks,
    Programs,
    Lessons,
};

export default stores