import React from 'react';
import styles from './OrderNowButton.module.css';

class OrderNowButton extends React.Component {

    constructor(props) {
        super(props);

    }



    render() {

        const ButtonContainer = {
            display: 'flex',
            alignItems: 'center',
            justifyContent: this.props.Position,
            marginLeft: this.props.MarginLeft,
            marginTop:this.props.MarginTop,
        };

       




        return (
            <div style={ButtonContainer}><a href={this.props.LinkTo}> <div className={styles.Button}>{this.props.ButtonText}</div></a></div>
        );
    }
}

export default OrderNowButton;


