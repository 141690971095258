import React, { Component } from 'react';
import axios from 'axios';
import clientConfig from '../client-config';
import styles from './PasswordReset.module.css';
import Loader from "../loader.gif";
import Radium from 'radium';
import { Link } from "react-router-dom";

import * as EmailValidator from 'email-validator';

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';


@inject('SiteLinks')
@observer // REMOVE BRACKETS => @observer
class PasswordReset extends Component {

	constructor(props) {
		super(props);

		this.state = {
			userEmail: '',
			loading: false,
			error: '',
			sent: false,
			errorMessage: ''
		}
	}


	enterPress = (event) => {
		if (event.key === 'Enter') {
			this.onFormSubmit()
		}
	}



	onFormSubmit = (event) => {

		// event.preventDefault();

		const siteUrl = clientConfig.siteUrl;
		const AUTH_KEY = clientConfig.AUTH_KEY;


		const userEmail = this.state.userEmail;


		let data_to_post = {}
		data_to_post.AUTH_KEY = AUTH_KEY
		data_to_post.user_email = userEmail

		this.setState({ loading: true }, () => {
			axios.post(`${siteUrl}/wp-json/wl/v1/send_password_reset_mail`, data_to_post, {
				headers: {
					'Content-Type': 'application/json',
				}
			})
				.then(res => {
					console.log(res.data)

					this.setState({ loading: false, sent: true });
					this.setState({ errorMessage: <div className="FormOk">A Password reset link has been sent to your email</div> });

				})
				.catch(err => {

					this.setState({ error: err.response.data.message, loading: false });

				})


			if (this.state.userEmail === '') {

				this.setState({ errorMessage: <div className="FormError">Enter an email</div> });

			}

			if (this.state.error) {
				EmailValidator.validate(userEmail) ?
					this.setState({ errorMessage: <div className="FormError">There is no account with that email address.</div> })
					:
					this.setState({ errorMessage: <div className="FormError">enter a valid email address</div> });
			}

		})








	};


	handleOnChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {

		const { userEmail, loading } = this.state;

		const BackgroundStyle = {
			backgroundImage: 'url(' + this.props.SiteLinks.ResetPasswordBackground + ')',

			//On moble screen
			'@media (max-width: 801px)': {
				backgroundImage: 'url(' + 	this.props.SiteLinks.ResetPasswordBackgroundMobile + ')',
			}

		};


		// let FormMessage = null;

		// if (this.state.error) {

		// 	FormMessage = <div className="FormError">There is no account with that email address.</div>
		// }

		// if (this.state.sent) {
		// 	FormMessage = <div className="FormOk">A new password has been sent to your email</div>;

		// }



		return (
			<div style={BackgroundStyle} className={styles.LoginConatiner + ' BackgroundContainer'}>

				<div className={styles.FormContainer}>
					<div style={{ width: '100%' }}>

						<div className="FormTitle">Reset Passpowrd</div>

						{this.state.errorMessage}

						<div className="form_item">
							<input
								placeholder="Email Address"
								type="text"
								className="form-input"
								name="userEmail"
								value={userEmail}
								onChange={this.handleOnChange}
								onKeyPress={this.enterPress}
							/>
						</div>



						<div className={styles.Submitbutton + " form_item"}>
							<button className="btn" onClick={this.onFormSubmit}>Get New Password</button>
						</div>
						<div className={styles.GoToLogin}> <Link to="/">Login Page</Link></div>
					</div>


					{loading && <img className="loader" src={Loader} alt="Loader" />}
				</div>

			</div>

		);


	}
}


export default Radium(PasswordReset);
