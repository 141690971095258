import React from 'react';

import styles from './UpgradePopUp.module.css';

import SelectProgramItem from '../SelectProgramItem';
import { Link } from 'react-router-dom'


import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';



const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1200 })
    return isMobile ? children : null
}



@inject('LoginDetails', 'Programs')
@observer // REMOVE BRACKETS => @observer
class UpgradePopUp extends React.Component {





    componentDidMount() {
        if (localStorage.getItem('TimeClicked')) {
            const TimeClicked = new Date(localStorage.getItem('TimeClicked'))
            const TimeNow = new Date()
            const TimePass = TimeNow.getTime() - TimeClicked.getTime()
            // console.log(TimePass)
            if (TimePass > 1000000) {
                localStorage.removeItem('HideClicked')
            }
        }

        setInterval(() => {
            if (localStorage.getItem('HideClicked') == null) {
                if (!this.props.LoginDetails.HavePlm2()) {
                    this.props.Programs.ShowUpgradeTrue()
                }
            }
        }, 5000);
    }

    onClick = () => {
        localStorage.setItem('HideClicked', true);
        localStorage.setItem('TimeClicked', new Date());
        this.props.Programs.ShowUpgradeFalse()

    }


    render() {

        const ShowStyle = {
            top: '0',
            transition: 'top 1.2s',

        };

        const HideStyle = {
            top: '-250px',
            transition: 'top 1.2s',

        };


        const ShowStyleMobile = {
            bottom: '0',

            transition: 'bottom 1s',

        };

        const HideStyleMobile = {
            bottom: '-60px',
            transition: 'bottom 1s',

        };



        return (

            <>
                <div className={styles.UpgradeContainer + ' ' + styles.UpgradeContainerDesktop} style={this.props.Programs.ShowUpgrade ? ShowStyle : HideStyle}>

                    <div className={styles.UpgradeTopBar}>
                        <div className={styles.Text}>Available on our store</div>
                        <div className={styles.Close} onClick={this.onClick}>X</div>
                    </div>
                    <Link className={styles.UpgradeInfo} to={`/play-machines-2`}>
                        <div className={styles.TextB}> Play Machines <span className="RomanNumber">II</span> <br /> <span className={styles.Text2}>Upgrade NOW!</span></div>
                    </Link>

                </div>




                <div className={styles.UpgradeContainerMobile} style={this.props.Programs.ShowUpgrade ? ShowStyleMobile : HideStyleMobile}>

                    <div className={styles.UpgradeTopBar}>
                        <div className={styles.Text}>Available on our store</div>
                        <div className={styles.Close} onClick={this.onClick}>X</div>
                    </div>
                    <Link className={styles.UpgradeInfo} to={`/play-machines-2`}>
                        <div className={styles.TextB}> Play Machines <span className="RomanNumber">II</span> <br /> <span className={styles.Text2}>Upgrade NOW!</span></div>
                    </Link>

                </div>


            </>

        );
    }
}

export default UpgradePopUp;

