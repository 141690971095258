import React from 'react';
import styles from './VideoAndText.module.css';
import '../../screens/LandingPage.css';

import Vimeo from '@u-wave/react-vimeo';

class VideoAndText extends React.Component {


    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {



        return (

            <div className={styles.FlexItemTowItemsRaw}>

                <div className={styles.ItemTowItemsRaw} >
                    {<div className={styles.Title}>{this.props.MainText}</div>}

                </div>


                <div className={styles.ItemTowItemsRaw} >

                    <div className={styles.Video}>
                        {/* <iframe
                            src={`https://www.youtube.com/embed/${this.props.VideoId}`}
                            frameBorder="0"
                        /> */}
                        <Vimeo video={this.props.VideoId}  autoplay={false}/>

                    </div>


                </div>

            </div>

        );
    }
}

export default VideoAndText;

