import React from 'react';
import styles from './MainInfo.module.css';
import Radium from 'radium';
class Maininfo extends React.Component {
    render() {


        const Style = {
            marginTop: this.props.MarginTopBigImage,

            '@media (max-width: 1200px)': {
                marginTop: this.props.MarginTopMobileBigImage,
                marginBottom: this.props.MarginBottomMobileBigImage,
            }
        }

        return (
            <>

                <div className={styles.MainInfo}>
                    {
                        this.props.loading ? <div></div> :
                            <div className={styles.Container}>
                                <div className={styles.LeftItem}>
                                    <img src={this.props.Logo} />
                                    <div className={styles.Lessons}>{this.props.Lessons}</div>
                                    {this.props.ShortDescription}
                                </div>
                                <div className={styles.RightItem} style={Style}><img src={this.props.MainImage} /></div>
                            </div>
                    }
                </div>

            </>
        );
    }
}

export default Radium(Maininfo);
