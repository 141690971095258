import { observable, configure, runInAction, action, autorun, computed } from 'mobx';
import parse from 'html-react-parser'
import axios from 'axios';
import clientConfig from '../client-config';

import WooCommerceAPI from 'woocommerce-api';
import wooConfig from '../wooConfig';

configure({ enforceActions: true });





class Pages {
    @observable Title = ''
    @observable Lessons = ''
    @observable Price = ''
    @observable LogoOnWhite = ''
    @observable MainImage = ''
    @observable ShortDescription = ''
    @observable MainText = ''
    @observable YoutubeId = ''
    @observable Gallery1 = []
    @observable Gallery2 = []
    @observable PlayMachinesSet = ''
    @observable LearningSubjects = ''
    @observable loading = false

    // @action aaa = () => {
    //    this.products=[]
    // };



    GetUrls(Ids, WhoToUpdate) {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.Ids = Ids

        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/get_urls`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {
                // console.log(WhoToUpdate)
                runInAction(() => {

                    switch (WhoToUpdate) {
                        case "LogoOnWhite":
                            return this.LogoOnWhite = res.data

                        case "Gallery1":
                            // console.log(res.data[0])
                            return this.Gallery1 = res.data

                        case "Gallery2":
                            return this.Gallery2 = res.data

                        case "Mainimage":
                            return this.MainImage = res.data

                        default: null
                    }


                });

            })
            .catch(err => {
                console.log('err GetUrls(Ids)')

            })
    }


    // export const get = async function(url) {
    //     const response = await fetch(url, {
    //       credentials: "include"    // added this for safari 10
    //     });
    //     return await response.json();
    //   };

    @action LoadProduct(ID) {
        this.loading = true
        const WooCommerce = new WooCommerceAPI({
            url: 'https://play.youngengineers.org/shop/',
            consumerKey: wooConfig.consumerKey,
            consumerSecret: wooConfig.consumerSecret,
            wpAPI: true,
            version: 'wc/v3'
        });

        WooCommerce.getAsync('products').then(res => {
          

            const products = JSON.parse(res.body)

            const filter = products.filter(x => x.id === ID)
            const filterOutArray = { ...filter[0] }
            const meta_data = filterOutArray.meta_data

            const FilterTitle = { ...meta_data.filter(x => x.key === 'title') }
            const Title = { ...FilterTitle[0] }

            const FilterLessons = { ...meta_data.filter(x => x.key === 'lessons') }
            const Lessons = { ...FilterLessons[0] }

            const Price = filterOutArray.price

            const FilterLogoOnWhite = { ...meta_data.filter(x => x.key === 'logowhite') }
            const FilterLogoOnWhiteArr = FilterLogoOnWhite[0].value.split(', ') //MAKE ARRAY FROM STRING
            this.GetUrls(FilterLogoOnWhiteArr, 'LogoOnWhite')

            const FilterMainImage = { ...meta_data.filter(x => x.key === 'mainimage') }
            const FilterMainImageArr = FilterMainImage[0].value.split(', ') //MAKE ARRAY FROM STRING
            this.GetUrls(FilterMainImageArr, 'Mainimage')

            const FilterMainText = { ...meta_data.filter(x => x.key === 'main_text') }
            const MainText = { ...FilterMainText[0] }

            const ShortDescription = filterOutArray.short_description

            const FilterGallery1 = { ...meta_data.filter(x => x.key === 'gallery1') }
            const FilterGallery1Arr = { ...FilterGallery1[0].value }
            this.GetUrls(FilterGallery1Arr, 'Gallery1')

            const FilterGallery2 = { ...meta_data.filter(x => x.key === 'gallery2') }
            const FilterGallery2Arr = { ...FilterGallery2[0].value }
            this.GetUrls(FilterGallery2Arr, 'Gallery2')


            const FilterPlayMachinesSet = { ...meta_data.filter(x => x.key === 'play_machines_set') }
            const FilterLearningSubjects = { ...meta_data.filter(x => x.key === 'learning_subjects_') }

            runInAction(() => {
                // console.log(meta_data)
                this.Title = Title.value
                this.Lessons = Lessons.value
                this.Price = Price
                this.MainText = MainText.value
                this.ShortDescription = ShortDescription
                this.PlayMachinesSet = FilterPlayMachinesSet[0].value
                this.LearningSubjects = FilterLearningSubjects[0].value
                this.loading = false
            });

        })
            .catch(err => {
                this.loading = false
            });

    };




    constructor() {
        // this.LoadProducts()


    }



    LoadProducts(id, showLoader) {

        const WooCommerce = new WooCommerceAPI({
            url: 'https://play.youngengineers.org/shop/',
            consumerKey: wooConfig.consumerKey,
            consumerSecret: wooConfig.consumerSecret,
            wpAPI: true,
            version: 'wc/v3'
        });

        WooCommerce.getAsync('products').then(res => {

            const products = JSON.parse(res.body)


            runInAction(() => {
                this.products = products
                // console.log(products)
            });

        })
            .catch(err => {

            });
    }









    LoadPageData(id, showLoader) {

        const wordPressSiteUrl = clientConfig.siteUrl;

        showLoader ? this.loading = true : this.loading = false
        // PUT IN PAGE THAT SHOW CONTENT
        // import Loader from "../loader.gif";
        // {this.props.pages.loading && <img className="loader" src={Loader} alt="Loader" />}

        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages/${id}`)
            .then(res => {
                let myResponse = res.data.content.rendered;
                runInAction(() => {

                    switch (id) {
                        case "51":  // the Number is Page id in wordpress
                            this.TermsOfUse = parse(myResponse)
                            Object.assign(this.TermsOfUse, myResponse.data)
                            // console.log(myResponse)
                            this.loading = false
                            return null

                        default:
                            this.loading = false
                            return null

                    }

                });
            })
            .catch(err => {
                this.loading = false
            })

    }








}
export default new Pages();





