import React from 'react';
// import { Router } from "@reach/router";

import { Switch, Route } from "react-router-dom";

import { StyleRoot } from 'radium';

import LoginScreen from './screens/LoginScreen';
import SelectProgram from './screens/Customer/SelectProgram';
import SelectModel from './screens/Customer/SelectModel';
import Lesson from './screens/Customer/Lesson';
import CustomerProfile from './screens/Customer/CustomerProfile';
import ContactUs from './screens/Customer/ContactUs';
import PasswordReset from './screens/PasswordReset';
import NoMatch from './screens/NoMatch';
import TermsOfUse from './screens/Customer/TermsOfUse'

import LandingPage from './screens/LandingPage';
import PlayMachies1 from './screens/shop/PlayMachines1andSet';
import PlayMachies12 from './screens/shop/PlayMachines1and2andSet';

import PackagesDirect from './screens/PackagesDirect';
import Packages from './screens/Packages';
import RedirectTo from './screens/shop/RedirectTo';
import PlayMachies2Upgrade from './screens/shop/PlayMachines2Upgrade';
import LogOut from './screens/LogOut'
import CompleteYourOrder from './screens/Customer/CompleteYourOrder';

import { Helmet } from "react-helmet";


import './App.css';


class App extends React.Component {
  render() {
    return (

      <StyleRoot>
        <div className="AppContainer">

          <Helmet>
            <script src="https://play.youngengineers.org/includs.js" type="text/javascript" />
          </Helmet>

          <Switch>
            <Route path="/packages-direct">
              <PackagesDirect />
            </Route>

            <Route path="/packages">
              <Packages />
            </Route>


            <Route path="/store-click-redirect">
              <RedirectTo />
            </Route>

            <Route path="/PasswordReset">
              <PasswordReset />
            </Route>

            <Route path="/select-program">
              <SelectProgram />
            </Route>

            <Route path="/select-model/:program">
              <SelectModel />
            </Route>

            <Route path="/model_building_instructions/:programName/:LessonsId">
              <Lesson />
            </Route>

            <Route path="/kit-instructions/:LessonsId">
              <Lesson />
            </Route>

            <Route path="/user-profile">
              <CustomerProfile />
            </Route>

            <Route path="/contact-us">
              <ContactUs />
            </Route>

            <Route path="/terms-of-use" >
              <TermsOfUse />
            </Route>

            <Route exact path="/">
              <LoginScreen />
            </Route>

            <Route path="/store" >
              <LandingPage />
            </Route>

            <Route path="/play-machines-1" >
              <PlayMachies1 />
            </Route>

            <Route path="/play-machines-1and2" >
              <PlayMachies12 />
            </Route>

            <Route path="/play-machines-2" >
              <PlayMachies2Upgrade />
            </Route>

            <Route path="/logout" >
              <LogOut />
            </Route>

            <Route path="/complete-your-order" >
              <CompleteYourOrder />
            </Route>

            <Route path="*">
              <NoMatch />
            </Route>

          </Switch>



        </div>
      </StyleRoot>

    );
  }
}

export default App;







