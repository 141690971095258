import React, { Component } from 'react';
import styles from './ComleteYourOrder.module.css';


import { isLoggedIn } from "../../components/functions";
import Loader from "../../loader.gif";

import NavbarEmpty from "../../components/Navbars/NavbarEmpty";

import { useMediaQuery } from 'react-responsive';

import { FaRegArrowAltCircleLeft } from 'react-icons/fa';
import { FiHome } from "react-icons/fi";

import Radium from 'radium';

import FormContactUs from '../../components/Customer/FromContactUs';

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';



const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}


@inject('LoginDetails', 'SiteLinks')
@observer // REMOVE BRACKETS => @observer
class CompleteYourOrder extends Component {




    componentDidMount() {
        this.props.LoginDetails.HaveActiveProgram()
    }

    handleLogout = () => {
        localStorage.clear();
        window.location.href = 'https://play.youngengineers.org/store';
    };


    render() {








        return (

            !isLoggedIn() ? (

                window.location.href = '/'

            ) :

                <React.Fragment>


                    <NavbarEmpty Title="" DisplayBack='none' DisplayHome='none' />

                    <div className='PageConatiner'>
                        {this.props.LoginDetails.loading && <img className="loader" src={Loader} alt="Loader" />}
                        <Desktop> <div className={styles.BackgroungImg}><img src={this.props.SiteLinks.WhiteBackground} alt="young engineers"></img></div> </Desktop>
                        <Mobile> <div className={styles.MobileBackgroungImg}><img src={this.props.SiteLinks.WhiteBackgroundMobile} alt="young engineers"></img></div> </Mobile>
                        <div style={{ height: '125px' }}></div>

                        <div className={styles.FlexContainer}>
                            <div className={styles.Title}>
                                Complete your order to login<br />
                        to your Young Engineers Online platform
                        </div>
                            <div className={styles.Button} onClick={this.handleLogout}>
                                Go To Store
                        </div>
                        </div>


                    </div>
                </React.Fragment>
        );


    }
}

export default Radium(CompleteYourOrder);
