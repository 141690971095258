import React from 'react';
import styles from './ShopItem.module.css';
import '../../screens/LandingPage.css';
import { Link } from 'react-router-dom';

import axios from 'axios'
import clientConfig from '../../client-config';

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import ReactHtmlParser, { htmlparser2 } from 'react-html-parser';

class ShopItem extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            currentSlide: 0,
        };
    }

    componentDidMount() {

    }


    next = () => {
        this.setState((state) => ({
            currentSlide: state.currentSlide + 1,
        }));
    };

    prev = () => {
        this.setState((state) => ({
            currentSlide: state.currentSlide - 1,
        }));
    };


    location = () => {
        window.location.href = this.props.ReadMore;
        return false;
        // window.location.assign(this.props.ReadMore)
        // document.location.replace(this.props.ReadMore)
        // window.location.replace(this.props.ReadMore);
    };



    updateCurrentSlide = (index) => {
        const { currentSlide } = this.state;

        if (currentSlide !== index) {
            this.setState({
                currentSlide: index,
            });
        }
    };

    render() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const TopImage = `${wordPressSiteUrl}/wp-content/uploads/2020/05/top_GreenDuble.png`
        const bullet = `${wordPressSiteUrl}/wp-content/uploads/2020/05/bullet.png`

        const BulletImage = {
            listStyleImage: 'url(' + bullet + ')',
            marginLeft: '5px'
        };


        const Images = this.props.GalleryImages.map((image, key) =>
            <div key={key}>
                <img src={image.src} />
            </div>
        );
        return (


            <div className={styles.ShopItem}>
                <div className={styles.TitleContainer} >
                    <img src={TopImage} />
                    <div className={styles.Title}>{this.props.Title}</div>
                </div>
                <div className={styles.InfoContainer}>
                    <img className={styles.logo} src={this.props.Logo} />
                    {/* <div className={styles.Lessons}>{this.props.Lessons}</div> */}

                    {ReactHtmlParser(this.props.ShortText)}

                    {/* <div className={styles.CarouselContainer}>

                        <div className={styles.ButtonNextPrev} >
                            <div onClick={this.prev} className={styles.ButtonContainer} > <FiChevronLeft /></div>
                        </div>
                        <div className={styles.CenterItem + ' ShopItem'}>
                            <Carousel
                                showThumbs={false}
                                showIndicators={false}
                                infiniteLoop={false}
                                selectedItem={this.state.currentSlide}
                                showArrows={true}
                                onChange={this.updateCurrentSlide}
                                showStatus={true}
                                {...this.props}
                            >
                                {Images}
                            </Carousel>
                        </div>
                        <div className={styles.ButtonNextPrev} >
                            <div onClick={this.next} className={styles.ButtonContainer} > <FiChevronRight /></div>
                        </div>
                    </div> */}
                    {/* <div className={styles.BuyNowContainer} ><a href={this.props.BuyNowLink}> <div className={styles.BuyNow}>Get Yours!</div></a></div> */}
                    {/* <Link to={this.props.ReadMore} className={styles.ReadMore}>See All Models</Link> */}
                    < div onClick={this.location} className={styles.ReadMore}>See All Models</div>
                </div>

            </div >

        );
    }
}

export default ShopItem;