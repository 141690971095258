import React from 'react';

import styles from './NoMatch.module.css';

import { Link } from "react-router-dom";

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';


@inject('SiteLinks')
@observer // REMOVE BRACKETS => @observer
class NoMatch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            ManuIsOpen: false,
        }
    }

    render() {
        return (
            <div className={styles.MainContainer}>
                <div className={styles.Title}>PAGE NOT FOUND</div>
                <img src={this.props.SiteLinks.NotFound}></img>

                <div className={styles.GoToLogin}> <Link to="/">GO BACK HOME</Link></div>
            </div>
        );
    }
}

export default NoMatch;