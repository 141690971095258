import React from 'react';

import styles from './SelectProgram.module.css';



import SelectProgramItem from '../SelectProgramItem';
import { Link } from 'react-router-dom'


import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

@inject('LoginDetails', 'Programs')
@observer // REMOVE BRACKETS => @observer
class SelectProgramGrid extends React.Component {




    render() {

        const Plm1 = this.props.Programs.Plm1.map((program) =>

            <div className={styles.Item}>
                <Link to={`/select-model/${program.metadata.program_name}/`}>
                    <SelectProgramItem
                        TopLegoImage={program.lego_long}
                        Title={program.metadata.title}
                        TopPosition='-7px'
                        ProgramLogo={program.program_logo}
                    />
                </Link>
            </div>

        );



        const Plm2 = this.props.Programs.Plm2.map((program) =>

            <div className={styles.Item}>
                <Link to={`/select-model/${program.metadata.program_name}/`}>
                    <SelectProgramItem
                        TopLegoImage={program.lego_long}
                        Title={program.metadata.title}
                        TopPosition='-7px'
                        ProgramLogo={program.program_logo}
                    />
                </Link>
            </div>

        );


        return (
            <div className={styles.FlexContainer}>
                <div className={styles.ItemsContainer}>


                    {this.props.HavePlm1 ? Plm1 : null}
                    {this.props.HavePlm2 ? Plm2 : null}





                </div>


            </div>

        );
    }
}

export default SelectProgramGrid;