import React, { Component } from 'react';
import styles from '../BuildingInstructions.module.css';


import { isLoggedIn } from "../../components/functions";

import NavbarCustomer from "../../components/Navbars/NavbarCustomer";

import { useMediaQuery } from 'react-responsive';

import { FaRegArrowAltCircleLeft } from 'react-icons/fa';
import { FiHome } from "react-icons/fi";

import Radium from 'radium';

import FormContactUs from '../../components/Customer/FromContactUs';

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';



const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}


@inject('LoginDetails', 'SiteLinks')
@observer // REMOVE BRACKETS => @observer
class ContactUs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            HaveChildName: false,
        }
    }

   


    ToggleResetPasword() {
        const clone = this.state.PasswordRest
        this.setState({ PasswordRest: !clone, })
    }


    render() {

        //  localStorage.clear();


        const EnterChildName = (
            <div>
                Enter Child Name
            </div>
        )


        const SelectProgram = (
            <div>
                Select Program
            </div>
        )



        return (

            // !isLoggedIn() ? (

            //     window.location.href = '/'

            // ) :

            <React.Fragment>


                <NavbarCustomer Title="Profile Page" GoBack={<FaRegArrowAltCircleLeft />} DisplayBack='none' Home={<FiHome />} />

                <div className='PageConatiner'>

                    <Desktop> <div className={styles.BackgroungImg}><img src={this.props.SiteLinks.WhiteBackground} alt="young engineers"></img></div> </Desktop>
                    <Mobile> <div className={styles.MobileBackgroungImg}><img src={this.props.SiteLinks.WhiteBackgroundMobile} alt="young engineers"></img></div> </Mobile>
                    <div style={{ height: '125px' }}></div>


                    <FormContactUs />

                </div>
            </React.Fragment>
        );


    }
}

export default Radium(ContactUs);
