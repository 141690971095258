import React, { Component } from 'react';
import axios from 'axios';
import clientConfig from '../../client-config';
import styles from './ProfilePasswordReset.module.css';
import Loader from "../../loader.gif";
import Radium from 'radium';
import { Link } from "react-router-dom";

import * as EmailValidator from 'email-validator';


import { FaRegArrowAltCircleLeft } from 'react-icons/fa';

class ProfilePasswordReset extends Component {

	constructor(props) {
		super(props);

		this.state = {
			userEmail: this.props.email,
			loading: false,
			error: '',
			sent: false,
			errorMessage: ''
		}
	}


	enterPress = (event) => {
		if (event.key === 'Enter') {
			this.onFormSubmit()
		}
	}



	onFormSubmit = (event) => {

		// event.preventDefault();

		const siteUrl = clientConfig.siteUrl;
		const AUTH_KEY = clientConfig.AUTH_KEY;


		const userEmail = this.state.userEmail;


		let data_to_post = {}
		data_to_post.AUTH_KEY = AUTH_KEY
		data_to_post.user_email = userEmail

		this.setState({ loading: true }, () => {
			axios.post(`${siteUrl}/wp-json/wl/v1/send_password_reset_mail`, data_to_post, {
				headers: {
					'Content-Type': 'application/json',
				}
			})
				.then(res => {
					// console.log(res)

					this.setState({ loading: false, sent: true });
					this.setState({ errorMessage: <div className="FormOk">A Password reset link has been sent to your email</div> });

				})
				.catch(err => {

					this.setState({ error: err.response.data.message, loading: false });

				})


			if (this.state.userEmail === '') {

				this.setState({ errorMessage: <div className="FormError">Enter an email</div> });

			}

			if (this.state.error) {
				EmailValidator.validate(userEmail) ?
					this.setState({ errorMessage: <div className="FormError">There is no account with that email address.</div> })
					:
					this.setState({ errorMessage: <div className="FormError">enter a valid email address</div> })


			}

		})








	};


	handleOnChange = (event) => {
		this.setState({ [event.target.name]: event.target.value });
	};

	render() {

		const { userEmail, loading } = this.state;




		return (
			<div className={styles.ResetConatiner}>

				<div className={styles.FormContainer}>
					<FaRegArrowAltCircleLeft className={styles.GoBack} onClick={this.props.onClick} />

					<div style={{ width: '100%' }}>

						<div className="FormTitle">Reset Passpowrd</div>

						{this.state.errorMessage}

						<div className="form_item">
							<input
								placeholder="Email Address"
								type="text"
								className="form-input"
								name="userEmail"
								value={userEmail}
								onChange={this.handleOnChange}
								onKeyPress={this.enterPress}
							/>
						</div>



						<div className={styles.Submitbutton + " form_item"}>
							<button className="btn" onClick={this.onFormSubmit}>Get New Password</button>
						</div>
						<div className={styles.GoToLogin}> <Link to="/">Login Page</Link></div>
					</div>


					{loading && <img className="loader" src={Loader} alt="Loader" />}
				</div>

			</div>

		);


	}
}


export default Radium(ProfilePasswordReset);
