import React, { Component, Suspense } from 'react';
import styles from './SinglePruduct.module.css';
import './SinglePruduct.css';
import clientConfig from '../../client-config';

import { hotjar } from 'react-hotjar';

import LoaderWhite from "../../loaderWhite.gif";

import { inject, observer } from 'mobx-react';
import { withRouter, BrowserRouter, Route } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';


import Vimeo from '@u-wave/react-vimeo';


import ProductHeader from '../../components/shop/ProductHeader';
import MainInfo from '../../components/shop/MainInfo'
import VideoAndText from '../../components/shop/VideoAndText'
import VideoAndGallery from '../../components/shop/VideoAndGallery'
import SingleVideoOnMobile from '../../components/shop/SingleVideoOnMobile'
import LogoAndCarousel from '../../components/shop/LogoAndCarousel'
import PlayMachinesSet from '../../components/shop/PlayMachinesSet'
import RawOrderNow from '../../components/shop/RawOrderNow'
import LearningSubjects from '../../components/shop/LearningSubjects'
import Footer from '../../components/landingPage/LandingFooter'




@inject('woo')
@observer // REMOVE BRACKETS => @observer
class PlayMachines1and2andSet extends Component {




    componentDidMount() {

        this.props.woo.LoadProduct(141)
        hotjar.initialize('1929700', 6);
    }



    render() {


        //         history.listen(({ action, location }) => {
        //   console.log(
        //     `The current URL is ${location.pathname}${location.search}${location.hash}`
        //   );
        //   console.log(`The last navigation action was ${action}`);
        // });


        const TopLogo = this.props.woo.loading

        const wordPressSiteUrl = clientConfig.siteUrl;

        // const LogoWhite = this.props.woo.LogoOnWhite
        const LogoWhite = `${wordPressSiteUrl}/wp-content/uploads/2020/06/plm12_white_andkit.png`

        // const MainImage = this.props.woo.MainImage
        const MainImage = `${wordPressSiteUrl}/wp-content/uploads/2020/06/PLM12_top.png`

        const Plm1Logo = `${wordPressSiteUrl}/wp-content/uploads/2020/06/PlayMachines1.png`
        const Plm2Logo = `${wordPressSiteUrl}/wp-content/uploads/2020/06/PlayMachines2.png`
        const PlmSet = `${wordPressSiteUrl}/wp-content/uploads/2020/06/PlmSet@2x.jpg`
        const computerImage = `${wordPressSiteUrl}/wp-content/uploads/2020/06/screenPLM1@2x.jpg`
        const LogoAndKit = `${wordPressSiteUrl}/wp-content/uploads/2020/05/plm12_and_kit.png`
        const kidImage = `${wordPressSiteUrl}/wp-content/uploads/2020/06/KidPLM1@2x.jpg`

        return (


            <>

                {this.props.woo.loading && <img className="loader" src={LoaderWhite} alt="Loader" />}


                <ProductHeader
                    GoBack={() => this.props.history.goBack()}
                    ButtonText='ORDER NOW!'
                    loading={this.props.woo.loading}
                    Title={ReactHtmlParser(this.props.woo.Title)}
                    Price={this.props.woo.Price}
                    PriceLine={'$119'}
                    OrderNowLink='https://play.youngengineers.org/shop/checkout/?add-to-cart=141'
                    Shipping={true}
                />
                <MainInfo
                    Lessons={this.props.woo.Lessons}
                    loading={TopLogo}
                    Logo={LogoWhite}
                    ShortDescription={ReactHtmlParser(this.props.woo.ShortDescription)}
                    MainImage={MainImage}
                    MarginTopBigImage='175px'
                    MarginTopMobileBigImage='50px'
                    MarginBottomMobileBigImage='0px'

                />
                <div className={styles.LogoContainer}><img src={Plm1Logo} /></div>
                <VideoAndGallery GalleryImages={this.props.woo.Gallery1} VideoId='435525733' VideoId2='435525755' MainText={ReactHtmlParser(this.props.woo.MainText)} />

                <div className={styles.LogoContainer}><img src={Plm2Logo} /></div>
                <VideoAndGallery GalleryImages={this.props.woo.Gallery2} VideoId='435525755' VideoId2='435525755' MainText={ReactHtmlParser(this.props.woo.MainText)} />

                {/* <LogoAndCarousel GalleryImages={this.props.woo.Gallery1} LogoFromLeft={false} LogoSrc={Plm1Logo} /> */}

                {/* <SingleVideoOnMobile VideoId='435525733' VideoId2='435525755' MainText={ReactHtmlParser(this.props.woo.MainText)} /> */}

                {/* <LogoAndCarousel GalleryImages={this.props.woo.Gallery2} LogoFromLeft={true} LogoSrc={Plm2Logo} /> */}
                <PlayMachinesSet Text={ReactHtmlParser(this.props.woo.PlayMachinesSet)} ImageSrc={PlmSet} />
                <RawOrderNow PriceLine="$119" Price={this.props.woo.Price} ButtonText='ORDER NOW!' Image1Src={computerImage} Image2Src={kidImage} LogoAndKitSrc={LogoAndKit} OrderNowLink='https://play.youngengineers.org/shop/checkout/?add-to-cart=141' />
                <LearningSubjects Text={ReactHtmlParser(this.props.woo.LearningSubjects)} />
                <Footer />
            </>
        );


    }
}

export default withRouter(PlayMachines1and2andSet);


