import { observable, configure, runInAction, action, toJS } from 'mobx';
import parse from 'html-react-parser'
import axios from 'axios';
import clientConfig from '../client-config';

import WooCommerceAPI from 'woocommerce-api';
import wooConfig from '../wooConfig';

configure({ enforceActions: true });


class LoginDetails {
    @observable loading = false
    @observable LoginDetails = ''
    @observable UserMeta = ''
    @observable MetaDataChanged = false
    @observable ChildName = ''




    @action
    HavePlm1() {
        const clone = { ...this.UserMeta }
        const plm1 = { ...clone.play_machines_one }
        if (plm1[0] === "1") {
            return true
        }
        return false
    }

    @action
    HavePlm2() {
        const clone = { ...this.UserMeta }
        const plm2 = { ...clone.play_machines_tow }
        if (plm2[0] === "1") {
            return true
        }
        return false

    }

    // if all programs is false Go to complete-your-order
    @action
    AllProgramsIsFalse() {
        if (!this.HavePlm1() && !this.HavePlm2()) {
            window.location.href = '/complete-your-order'
        }
    }






    // Function Inside complete-your-order page - Update User meta and Cheking if hava active program 
    @action HaveActiveProgram() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        const Id = localStorage.getItem('userId')

        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.user_id = Id
        this.loading = true
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/user_all_meta_data`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })

            .then(res => {
                // console.log(res.data)

                if (!res.data) {
                    window.location.href = '/logout'
                }

                else {
                    runInAction(() => { this.loading = false });
                    const BeforFatch = JSON.parse(localStorage.getItem('user_metadata'))
                    localStorage.setItem('user_metadata', JSON.stringify(res.data))
                    const AfterFatch = JSON.parse(localStorage.getItem('user_metadata'))

                    const IsEqual = JSON.stringify(BeforFatch) == JSON.stringify(AfterFatch)

                    if (!IsEqual) {
                        window.location.reload(false);
                    }
                    if (this.HavePlm1() || this.HavePlm2()) {
                        window.location.href = '/'
                    }

                }

            })
            .catch(err => {
                runInAction(() => { this.loading = false });
                console.log('err user_metadata')


            })

    }
    // LoadUserMeta END




    @action isCustomer() {
        if (localStorage.getItem('userRoles') === 'customer') {
            return true
        }
        else
            return false
    }

    @action isAdmin() {
        if (localStorage.getItem('userRoles') === 'administrator') {
            return true
        }
        else
            return false
    }



    constructor() {
        const login_details = localStorage.getItem('login_details')
        const metaData = localStorage.getItem('user_metadata')
        this.LoginDetails = JSON.parse(login_details);
        this.UserMeta = JSON.parse(metaData);

        const child_name = metaData ? JSON.parse(metaData).child_name : ''
        this.ChildName = child_name
        localStorage.setItem('child_name', child_name);

    };


    // LoadUserMeta
    @action UpdateUserMeta() {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        const Id = localStorage.getItem('userId')

        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.user_id = Id
        this.loading = true
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/user_all_meta_data`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })

            .then(res => {
                // console.log(res.data)

                if (!res.data) {
                    window.location.href = '/logout'
                }

                else {
                    runInAction(() => { this.loading = false });
                    const BeforFatch = JSON.parse(localStorage.getItem('user_metadata'))
                    localStorage.setItem('user_metadata', JSON.stringify(res.data))
                    const AfterFatch = JSON.parse(localStorage.getItem('user_metadata'))

                    const IsEqual = JSON.stringify(BeforFatch) == JSON.stringify(AfterFatch)
                    this.AllProgramsIsFalse();
                    if (!IsEqual) {
                        window.location.reload(false);
                    }
                }

            })
            .catch(err => {
                runInAction(() => { this.loading = false });
                console.log('err user_metadata')


            })

    }
    // LoadUserMeta END



    @action UpdateMetaItem(Field, Update) {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        const UserId = localStorage.getItem('userId')
        const FieldName = Field;
        const FieldNameUpdate = Update;

        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.UserId = UserId
        data_to_post.FieldName = FieldName
        data_to_post.FieldNameUpdate = FieldNameUpdate
        this.loading = true
        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/UpdateMetaItem`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })

            .then(res => {
                this.loading = false
                res



            })
            .catch(err => {
                this.loading = false
                console.log('UpdateMetaItem')
            })

    }




};









export default new LoginDetails();





