import React, { Component, Suspense } from 'react';
import styles from './PackagesDirect.module.css';
import { inject, observer } from 'mobx-react';

import { withRouter, Link, Redirect } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';

import { CgBoy, CgGirl } from 'react-icons/cg';
import { BiSmile } from 'react-icons/bi';

class LinkItem extends Component {

    render() {
        return (
            <Link to={this.props.LinkTo}>
                <div className={styles.LinkItem}>
                    <div className={styles.LinkContainer} >
                        <div className={styles.LinkItemTitle}>{this.props.Title}</div>
                        <div className={styles.IconsContainer}> {this.props.children}</div>
                    </div>
                </div>
            </Link>

        );
    }
}


@inject('SiteLinks')
@observer // REMOVE BRACKETS => @observer
class PackagesDirect extends Component {



    // componentDidMount() {

    //     if (this.props.lastLocation) {
    //         console.log(this.props.lastLocation.pathname)
    //         const LastLocation = this.props.lastLocation.pathname
    //         if (LastLocation.includes('/packages')) {
    //             this.props.history.goBack();
    //         }
    //     }

    // }



    constructor(props) {
        super();

        this.state = {
            Data: [],
            ShopData: [],
            IsShopData: false,
            loading: false,
            StoreLoading: false,
        }
    }








    render() {

        if (this.props.lastLocation) {
            console.log(this.props.lastLocation.pathname)
            const LastLocation = this.props.lastLocation.pathname
            if (LastLocation.includes('/packages')) {
                return <Redirect to='/store' />
            }
        }



        return (


            <React.Fragment>

                <div className={styles.PageConatiner}>
                    <div className={styles.FrameContainer}>
                        <div className={styles.BackgroundImg}><img src={this.props.SiteLinks.WhiteLegoBack} /></div>
                        <div className={styles.LogoYe}><img src={this.props.SiteLinks.young_engineers_logo} /></div>
                        <div className={styles.BigTitle}>We are new in the hood, <br />Help us with the Algorithm <BiSmile className={styles.Smile} /></div>

                        <div className={styles.SmallTitle}>I am a parent to:</div>

                        <div className={styles.LinkItemsContainer}>

                            <div className={styles.set1}>
                                <LinkItem Title="6-12 Boy" LinkTo='/packages?item=101'  >
                                    <CgBoy size='55' className={styles.LinkIcon} />
                                </LinkItem>

                                <LinkItem Title="6-12 Girl" LinkTo='/packages?item=102'  >
                                    <CgGirl size='55' className={styles.LinkIcon} />
                                </LinkItem>
                            </div>
                            <div className={styles.set2}>
                                <LinkItem Title="Both" LinkTo='/packages?item=103'  >
                                    <CgBoy size='55' className={styles.LinkIcon} />
                                    <CgGirl size='55' className={styles.LinkIcon} />
                                </LinkItem>

                                <LinkItem Title="Other" LinkTo='/packages?item=104'  >
                                </LinkItem>
                            </div>
                        </div>

                        <Link className={styles.LinkItemSkip} to='/packages?item=105'>
                            <div className={styles.LinkContainer} >
                                <div className={styles.LinkItemTitle}>Skip</div>
                            </div>
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        );


    }
}

export default withLastLocation(withRouter(PackagesDirect));

