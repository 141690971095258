import React, { Component } from 'react';
import styles from '../Customer/TermsOfUse.module.css';


import { isLoggedIn } from "../../components/functions";

import NavbarCustomer from "../../components/Navbars/NavbarCustomer";

import { useMediaQuery } from 'react-responsive';

import { FaRegArrowAltCircleLeft } from 'react-icons/fa';
import { FiHome } from "react-icons/fi";

import Radium from 'radium';


import ReactHtmlParser from 'react-html-parser';

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import Loader from "../../loader.gif";


const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}


@inject('LoginDetails', 'SiteLinks', 'Pages')
@observer // REMOVE BRACKETS => @observer
class TermsOfUse extends Component {





    render() {


        return (

            // !isLoggedIn() ? (

            //     window.location.href = '/'

            // ) :

                <React.Fragment>

                    {this.props.Pages.loading && <img className="loader" src={Loader} alt="Loader" />}

                  
                    <NavbarCustomer Title="Terms of Use" DisplayBack='none' Home={<FiHome />} />

                    <div className='PageConatiner'>

                        <Desktop> <div className={styles.BackgroungImg}><img src={this.props.SiteLinks.WhiteBackground} alt="young engineers"></img></div> </Desktop>
                        <Mobile> <div className={styles.MobileBackgroungImg}><img src={this.props.SiteLinks.WhiteBackgroundMobile} alt="young engineers"></img></div> </Mobile>
                        <div style={{ height: '125px' }}></div>


                        <div className={styles.TermsOfUse}>
                            <div className={styles.TermsOfUseContainer}>
                                {ReactHtmlParser(toJS(this.props.Pages.TermsOfUse))}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
        );


    }
}

export default Radium(TermsOfUse);
