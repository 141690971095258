import React from 'react';
import styles from './NavbarCustomer.module.css';
import { getChildName, IsCustomer } from "../functions";
import { slide as Menu } from 'react-burger-menu'
import NavLink from '../NavLink';
import { withRouter, Link, Redirect } from "react-router-dom";

import { PopupMenu, PopupTable } from 'react-rectangle-popup-menu';
import { GoGear } from "react-icons/go";
import { RiAccountCircleLine, RiStoreLine } from "react-icons/ri"
import { AiOutlineMail } from "react-icons/ai";
import { FiGlobe } from "react-icons/fi";

import './Navbar.css'
import Radium from 'radium';

import PopUpLink from '../Navbars/PopUpLink'
import PopUpLinkHref from '../Navbars/PopUpLinkHref'

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';


@inject('LoginDetails', 'SiteLinks')
@observer // REMOVE BRACKETS => @observer
class NavbarAdmin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ManuIsOpen: false,
        }
    }


    handleLogout = () => {
        localStorage.clear();
        window.location.href = '/';
    };


    render() {
        // const userName = (getUserName()) ? getUserName() : '';

        const ChildName = (getChildName()) ? getChildName() : '';
        // console.log(getChildName())
        const BackgroundStyle = {
            backgroundImage: 'url(' + this.props.SiteLinks.CustomerHeader + ')',



        };

        let Name = null
        if (ChildName !== '') {
            Name = (

                <div className={styles.StudentName}>
                    Hi {ChildName}
                </div>

            )
        }

        let KitFirstClick = ''
        if (IsCustomer()) {
            KitFirstClick = 'KitOnHeaderFirstClick'
            if (this.props.LoginDetails.UserMeta) {

                const KitFirstClickValue = toJS(this.props.LoginDetails.UserMeta.kit_on_header_clicked[0])

                if (KitFirstClickValue == 1) {
                    KitFirstClick = ''
                }
            }

        }

        return (
            <>

                <Menu customBurgerIcon={<GoGear className={styles.GearIcon} />} right>
                    <PopUpLink
                        LinkTo="/user-profile"
                        Icon={<RiAccountCircleLine />} Left="0px" FontSize="30px" Top='5px' Text="My Profile" MarginTop="35px" />
                    <PopUpLink
                        LinkTo="/store"
                        Icon={<RiStoreLine />} Left="0px" FontSize="30px" Top='4px' Text="Store" />
                    <PopUpLink
                        LinkTo="/contact-us"
                        Icon={<AiOutlineMail />} Left="0px" FontSize="30px" Top='4px' Text="Contac Us" />
                    <PopUpLinkHref
                        LinkTo="https://youngengineers.org/"
                        Icon={<FiGlobe />} Left="0px" FontSize="30px" Top='4px' Text="Main Site" />

                    <div className={styles.PopUpLogOutContainer}>
                        <div className={styles.PopUpLogOut} onClick={this.handleLogout} >Log out</div>
                    </div>

                    <div className={styles.PopUpTermsContainer}>
                        <NavLink className={styles.PopUpLink} to={`/terms-of-use`}>
                            <div className={styles.PopUpTerms}>Terms of use</div>
                        </NavLink>
                    </div>
                </Menu>


                <div className={styles.NavigationContainer} >

                    <div style={BackgroundStyle} className={styles.NavBar} >


                        <div className={styles.LeftDiv}>
                            {Name}
                            <NavLink to={`/kit-instructions/793`}>

                                <div className={styles.KitOnHeader + ' ' + KitFirstClick}>
                                    <img src={this.props.SiteLinks.Kit} />
                                </div>
                            </NavLink>
                        </div>


                        <div className={styles.CenterDivStudent}>
                            <div className={styles.TitleAndLink}>
                                <div onClick={() => this.props.history.goBack()} className={styles.StudentGoBack}>{this.props.GoBack}</div>
                                <div className={styles.StudentTitle}>{this.props.Title}</div>

                            </div>
                        </div>
                        <div className={styles.RightDiv}>

                            <div>
                                <div className={styles.PopupMenuContainer}>
                                    <PopupMenu
                                        height='auto'
                                        width={220}
                                        direction="bottom"
                                        button={<GoGear className={styles.GearIcon} />}
                                    >
                                        <div className={styles.LinksContainer}>
                                            {/* <PopUpLink
                                                LinkTo="/user-profile"
                                                Icon={<RiAccountCircleLine />} Left="0px" FontSize="30px" Top='5px' Text="My Profile" MarginTop="35px" /> */}
                                            <PopUpLink
                                                LinkTo="/store"
                                                Icon={<RiStoreLine />} Left="0px" FontSize="30px" Top='4px' Text="Store"  MarginTop="35px" />
                                            {/* <PopUpLink
                                                LinkTo="/contact-us"
                                                Icon={<AiOutlineMail />} Left="0px" FontSize="30px" Top='4px' Text="Contac Us" /> */}


                                            <PopUpLinkHref
                                                LinkTo="https://youngengineers.org/"
                                                Icon={<FiGlobe />} Left="0px" FontSize="30px" Top='4px' Text="Main Site" />


                                            <div className={styles.PopUpLogOutContainer}>
                                                <div className={styles.PopUpLogOut} onClick={this.handleLogout} >Log out</div>
                                            </div>

                                            <div className={styles.PopUpTermsContainer}>
                                                <NavLink className={styles.PopUpLink} to={`/terms-of-use`}>
                                                    <div className={styles.PopUpTerms}>Terms of use</div>
                                                </NavLink>
                                            </div>

                                        </div>
                                    </PopupMenu></div>
                                {/* <NavLink className="TermsNavLink" to={`/terms_of_use`}>Terms Of Use</NavLink> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.CenterItemOutOfHeader}>
                    <div onClick={() => this.props.history.goBack()} className={styles.StudentGoBackOutOfHeader}>{this.props.GoBack}</div>
                    <div className={styles.StudentTitleOutOfHeader}>{this.props.Title}</div>
                </div>
            </>
        );
    }
}

export default withRouter(Radium(NavbarAdmin));


