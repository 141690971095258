import React, { Component } from 'react';

import styles from '../../components/Customer/ProfileInfo.module.css';

import Loader from "../../loader.gif";
import { Link } from 'react-router-dom'
import { GrEdit, GrCheckboxSelected, GrCheckbox } from "react-icons/gr";

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';


@inject('LoginDetails', 'SiteLinks')
@observer // REMOVE BRACKETS => @observer
class ProfileInfo extends Component {









    render() {


        console.log(toJS(this.props.LoginDetails.ChildName[0]))

        return (
            <div>

                {/* {loading && <img className="loader" src={Loader} alt="Loader" />} */}
                <div className={styles.FlexContainer}>


                    <div className={styles.WhiteBox} >

                        <div className={styles.ItemsContainer}>
                            <div className={styles.Title}>Profile Inforamtion</div>


                            <div className={styles.Item}>
                                <div className={styles.Desciption}>Email / User Name</div>
                                <div className={styles.Data}>{toJS(this.props.LoginDetails.LoginDetails.userEmail)}</div>

                                <div className={styles.Action}><GrEdit className={styles.GrIcon} stroke='#F27A00' /></div>
                            </div>


                            <div className={styles.Item}>
                                <div className={styles.Desciption}>Child Name</div>
                                <div className={styles.Data}>{toJS(this.props.LoginDetails.ChildName[0])}</div>
                                <div className={styles.Action}><GrEdit className={styles.GrIcon} /></div>
                            </div>

                            <div className={styles.Item}>
                                <div className={styles.Desciption}>Packages</div>
                                <div className={styles.Data}>


                                    <div className={styles.DataItem}>
                                        <div>
                                            {this.props.LoginDetails.HavePlm1()
                                                ?
                                                < GrCheckboxSelected className={styles.GrCheckboxSelected} />
                                                :
                                                <GrCheckbox className={styles.GrCheckboxSelected} />
                                            }
                                        </div>
                                        <div> Play Machines </div>
                                        <div className={styles.RomanNumberSize + " RomanNumber"}>I</div>
                                    </div>

                                    <div className={styles.DataItem}>
                                        <div>
                                            {this.props.LoginDetails.HavePlm2()
                                                ?
                                                < GrCheckboxSelected className={styles.GrCheckboxSelected} />
                                                :
                                                <GrCheckbox className={styles.GrCheckboxSelected} />
                                            }
                                        </div>
                                        <div> Play Machines </div>
                                        <div className={styles.RomanNumberSize + " RomanNumber"}>II</div>
                                        {this.props.LoginDetails.HavePlm2() ? null : <Link to="/play-machines-2" className={styles.UpgradeNow}>UPGRADE NOW!</Link>}
                                    </div>
                                </div>
                                <div className={styles.Action}></div>
                            </div>


                            <div className={styles.Item}>
                                <div className={styles.Desciption}>Password Reset</div>
                                <div className={styles.RestPassword} onClick={this.props.onClick}>
                                    Get New Password
                                    </div>


                                <div className={styles.Action}></div>
                            </div>

                        </div>





                    </div>
                </div>
            </div>
        );


    }
}

export default ProfileInfo;
