import React from 'react';
import styles from '../landingPage/LandingFooter.module.css';
import clientConfig from '../../client-config';

class LandingFooter extends React.Component {

    render() {


        const wordPressSiteUrl = clientConfig.siteUrl;
        const YeLogo = `${wordPressSiteUrl}/wp-content/uploads/2020/05/YeWhite.png`;
        const IconsYouTube = `${wordPressSiteUrl}/wp-content/uploads/2020/05/Youtube.png`;
        const IconsMail = `${wordPressSiteUrl}/wp-content/uploads/2020/05/ContactUs.png`;


        return (
            <div className={styles.Container}>
                <div className={styles.FooterContainer}>
                    <div className={styles.LeftItem}><img src={YeLogo} /></div>
                    <div className={styles.RightItem}>
                        <div className={styles.RawOne}>
                            <a href="https://www.youtube.com/channel/UCPvEDjBLxTtit3wGCr1OPZA"  className={styles.YouTube}>

                                <img src={IconsYouTube} />
                            </a>
                            <a href="mailto:support@youngengineers.org" className={styles.Contactus}>

                                <div className={styles.IconMail}><img src={IconsMail} /></div>

                                <div>Contact Us</div>

                            </a>
                        </div>

                        <a href="https://youngengineers.org/"><div className={styles.RawTow}>www.youngengineers.org</div></a>
                    </div>
                </div>
            </div>
        );
    }
}

export default LandingFooter;