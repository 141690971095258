import { observable, configure, runInAction, action, toJS } from 'mobx';
import parse from 'html-react-parser'
import axios from 'axios';
import clientConfig from '../client-config';

import WooCommerceAPI from 'woocommerce-api';
import wooConfig from '../wooConfig';

configure({ enforceActions: true });


const wordPressSiteUrl = clientConfig.siteUrl;
// const Random = Math.random()
const Version = Math.random()

class SiteLinks {


    @observable young_engineers_logo = `${wordPressSiteUrl}/wp-content/uploads/2020/07/young_engineers_logo.png`
    @observable OnlineLogo = `${wordPressSiteUrl}/wp-content/uploads/2020/07/online_logo.png`

    @observable LoginBackground = `${wordPressSiteUrl}/wp-content/uploads/2020/06/LoginBackground.jpg`
    @observable LoginBackgroundMobile = `${wordPressSiteUrl}/wp-content/uploads/2020/06/LoginBackgroundMobile.jpg`

    @observable WhiteBackground = `${wordPressSiteUrl}/wp-content/uploads/2020/07/WhiteBackground.jpg`
    @observable WhiteBackgroundMobile = `${wordPressSiteUrl}/wp-content/uploads/2020/07/WhiteBackgroundMobile.jpg`

    @observable ResetPasswordBackground = `${wordPressSiteUrl}/wp-content/uploads/2020/07/ResetPasswordBackground.jpg`
    @observable ResetPasswordBackgroundMobile = `${wordPressSiteUrl}/wp-content/uploads/2020/07/ResetPasswordBackgroundMobile.jpg`

    @observable CustomerHeader = `${wordPressSiteUrl}/wp-content/uploads/2020/07/header_customer.jpg`
    @observable SelectProgramBack = `${wordPressSiteUrl}/wp-content/uploads/2020/06/select_program.jpg`
    @observable SelectProgramBackMobile = `${wordPressSiteUrl}/wp-content/uploads/2020/06/select_programMobile.jpg`

    @observable Plm1Logo = `${wordPressSiteUrl}/wp-content/uploads/2020/11/LogoPlayMachines1.png`
    @observable Plm2Logo = `${wordPressSiteUrl}/wp-content/uploads/2020/11/LogoPlayMachines2.png`

    @observable Kit = `${wordPressSiteUrl}/wp-content/uploads/2020/07/Kit.png`

    @observable NotFound = `${wordPressSiteUrl}/wp-content/uploads/2020/08/404.jpg`

    // LANDING PAGE
    @observable WormRobot = `${wordPressSiteUrl}/wp-content/uploads/2020/10/WormRobot.jpg?V=${Version}`
    @observable Swing = `${wordPressSiteUrl}/wp-content/uploads/2020/10/Swing.jpg?V=${Version}`
    @observable kitAndTablet = `${wordPressSiteUrl}/wp-content/uploads/2020/11/ViewPackages.jpg?V=${Version}`
    @observable WhiteLegoBack = `${wordPressSiteUrl}/wp-content/uploads/2020/10/LegoBackWhite.jpg?V=${Version}`
   
    @observable LegoGreen = `${wordPressSiteUrl}/wp-content/uploads/2020/05/top_GreenDuble.png?V=${Version}`
    @observable VideoIcon = `${wordPressSiteUrl}/wp-content/uploads/2020/11/video.png?V=${Version}`
    @observable ExploreIcon = `${wordPressSiteUrl}/wp-content/uploads/2020/11/explore.png?V=${Version}`
    @observable PlayIcon = `${wordPressSiteUrl}/wp-content/uploads/2020/11/play.png?V=${Version}`

    @observable Plm2BackgroungDesktop = `${wordPressSiteUrl}/wp-content/uploads/2020/06/PLAY_MACHINES_2.jpg?V=${Version}`
    @observable Plm2BackgroungMobile = `${wordPressSiteUrl}/wp-content/uploads/2020/06/PLAY_MACHINES_2_Mobile.jpg?V=${Version}`
  
};

export default new SiteLinks();





