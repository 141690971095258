export const isLoggedIn = () => {

	return localStorage.getItem('token');
};

export const getUserName = () => (
	localStorage.getItem('userName')
);


export const IsAdministrator = () => {

	if (localStorage.getItem('userRoles') === 'administrator') {
		return true
	}
	else
		return false
}

export const IsCustomer = () => {

	if (localStorage.getItem('userRoles') === 'customer') {
		return true
	}
	else
		return false
}

export const getChildName = () => {
	console.log(IsCustomer())
	if (IsCustomer()) {
		if ("user_metadata" in localStorage) {
			const metaData = localStorage.getItem('user_metadata')
			let ChildName = ''
			if (JSON.parse(metaData).child_name[0] !== null) {
				ChildName = JSON.parse(metaData).child_name[0]
			}
			return ChildName
		} else {
			return null
		}
	}
};




export const isFranchiseOwner = () => {

	if (localStorage.getItem('userRoles') === 'franchise_owner' || localStorage.getItem('userRoles') === 'administrator') {
		return true
	}
	else
		return false
}


export const isAdministrator = () => {

	if (localStorage.getItem('userRoles') === 'administrator') {
		return true
	}
	else
		return false
}


export const isStudent = () => {

	if (localStorage.getItem('userRoles') === 'student') {
		return true
	}
	else
		return false
}



