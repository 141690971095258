import React, { Component } from 'react';

import styles from '../../screens/BuildingInstructions.module.css';

import { isLoggedIn } from "../../components/functions";

import Navbarcustomer from "../../components/Navbars/NavbarCustomer";

import { useMediaQuery } from 'react-responsive';

import { withRouter } from 'react-router-dom'

import { FaRegArrowAltCircleLeft } from 'react-icons/fa';
import { FiHome } from "react-icons/fi";

import SelectModelContent from '../Customer/SelectModelContent'
import SelectModelContentLoader from '../Customer/SelectModelContentLoader'

import UpgradePopUp from '../../components/Customer/UpgradePopUp';

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
    return isMobile ? children : null
}




@inject('LoginDetails', 'SiteLinks', 'Programs', 'Lessons')
@observer // REMOVE BRACKETS => @observer
class ModelsPage extends Component {



    renderSwitchForLogo(param) {

        if (this.props.Programs.FatchEnd) {
            switch (param) {
                case 'play-machines-1':
                    return <img src={toJS(this.props.Programs.Plm1[0].program_logo[0])} alt="Play Machines 1"></img>;
                case 'play-machines-2':
                    return <img src={toJS(this.props.Programs.Plm2[0].program_logo[0])} alt="Play Machines 2"></img>;
                default:
                    return null;
            }
        }
    };



    SwitchDesktopBack(param) {
        if (this.props.Programs.FatchEnd) {
            switch (param) {
                case 'play-machines-1':
                    return toJS(this.props.Programs.Plm1[0].program_desktop_backgroung[0]);
                case 'play-machines-2':
                    return toJS(this.props.Programs.Plm2[0].program_desktop_backgroung[0]);
                default:
                    return null;
            }
        }
    };


    SwitchMobileBack(param) {
        if (this.props.Programs.FatchEnd) {
            switch (param) {
                case 'play-machines-1':
                    return toJS(this.props.Programs.Plm1[0].program_mobile_backgroung[0]);
                case 'play-machines-2':
                    return toJS(this.props.Programs.Plm2[0].program_mobile_backgroung[0]);
                default:
                    return null;
            }
        }


    };




    render() {
        const { match } = this.props;
        const ProgramName = match.params.program;
        const ProgramNameNoSpaces = ProgramName.replace(/\s+/g, '');

        let Header = <Navbarcustomer Title="Open Your Model" GoBack={<FaRegArrowAltCircleLeft />} Home={<FiHome />} />


        return (




            !isLoggedIn() ? (

                window.location.href = '/'

            ) :

                <React.Fragment>
                    <UpgradePopUp />
                    {Header}
                    {/* {this.props.Lessons.loading && <img className="loader" src={Loader} alt="Loader" />} */}

                    <div className='PageConatiner'>

                        <div className={styles.ProgramLogo} >
                            {this.renderSwitchForLogo(ProgramNameNoSpaces)}
                        </div>

                        <Desktop> <div className={styles.BackgroungImg}><img src={this.SwitchDesktopBack(ProgramNameNoSpaces)} alt="young engineers logo"></img></div> </Desktop>
                        <Mobile> <div className={styles.MobileBackgroungImg}><img src={this.SwitchMobileBack(ProgramNameNoSpaces)} alt="young engineers logo"></img></div> </Mobile>

                        {this.props.Lessons.loading &&
                            <div className="ProgramsLoader">
                                <div className={styles.ProgramLogo} >
                                    <img src='https://play.youngengineers.org/shop/wp-content/uploads/2020/06/LogoEmpty.png' alt="Play Machines 1"></img>
                                </div>
                                <SelectModelContentLoader /> </div>
                        }


                        <SelectModelContent ProgramName={match.params.program} />

                    </div>
                </React.Fragment>

        );


    }
}

export default withRouter(ModelsPage);
