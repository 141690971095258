import React from 'react';
import { Link } from 'react-router-dom'
import styles from '../Customer/Modelitem.module.css';

import { isLoggedIn, isFranchiseOwner } from "../../components/functions";

import BottomShadow from '../../images/BottomShadow.png'
// https://play.youngengineers.org/shop/wp-content/uploads/2020/06/BottomShadow.png
import { FaPlay } from 'react-icons/fa';

class ModelItem extends React.Component {


    render() {



        const BottomColor1 = {
            backgroundColor: this.props.BottomColor1
        }

        const BottomColor2 = {
            backgroundColor: this.props.BottomColor2
        }




        return (

            <>

                <div className={styles.Item} key={this.props.ItemId}>
                    <Link to={`/model_building_instructions/${this.props.programName}/${this.props.LessonsId}`}>
                        <div className={styles.TitleContainer}>
                            <div className={styles.Title + ' ' + this.props.TitleclassName}>{this.props.Title}</div>
                            <img className={styles.TopTitleBackground} src={this.props.TitleBackColor} alt="TopTitleBackground"></img>
                        </div>

                        <div className={styles.ModelPic}>
                            <img className={styles.TopTitleBackground} src={this.props.ModelPic} alt="ModelPic"></img>
                        </div>


                        <div style={BottomColor1} className={styles.BottomArea}>
                            <div style={BottomColor2} className={styles.Number}>{this.props.Number}</div>
                            <div className={styles.PlayIcon}><FaPlay className={styles.FaPlay} /></div>
                        </div>


                        <div className={styles.BottomShadow}>
                            <img className={styles.TopTitleBackground} src={BottomShadow} alt="BottomShadow"></img>
                        </div>
                    </Link>
                </div>


            </>

        );
    }
}

export default ModelItem;