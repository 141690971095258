import React from 'react';

import styles from '../../screens/LandingPage.module.css';

class LearningByMaking extends React.Component {

    constructor(props) {
        super(props);

    }



    render() {

        return (
            <div className={styles.learning_by_making}>
                <div className={styles.Item + ' ' + styles.Item1}> <img src={this.props.Img1} /></div>
                <div className={styles.Item + ' ' + styles.Item2}> Learning by making<br />is the name of the game.</div>
                <div className={styles.Item + ' ' + styles.Item3}>  <img src={this.props.Img2} /></div>
            </div>
        );
    }
}

export default LearningByMaking;


