import React from 'react';
import { observable, configure, runInAction, action, autorun, computed, toJS } from 'mobx';

import { withRouter, Link } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';

import axios from 'axios';
import clientConfig from '../client-config';

configure({ enforceActions: true });


class LandingPage {
    @observable Data = []
    @observable loading = false



    constructor() {
        this.LoadPageContent('88')
    }

    LoadPageContent(id) {
        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.PageId = id

        axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/get_landing_page_content`, data_to_post, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
            .then(res => {


                const Data = toJS(res.data);

                const Gallery = res.data.Gallery;
                runInAction(() => {
                    Object.assign(this.Data, Data)
                  
                    this.loading = false
                });
            })
            .catch(err => {

            })
    }


}

export default new withLastLocation(withRouter(LandingPage));





