
import React from 'react';

class LogOut extends React.Component {

    componentDidMount() {
        localStorage.clear();
        window.location.href = '/';
    }

    render() {
        return (
            <div>

            </div>
        );
    }
}

export default LogOut;

