import React, { Component } from 'react';
import axios from 'axios';
import clientConfig from '../client-config';
import styles from './LoginScreen.module.css';
import Loader from "../loader.gif";

import Checkbox from 'rc-checkbox';
import { useMediaQuery } from 'react-responsive';

import Radium from 'radium';
import { Redirect, Link } from 'react-router-dom'

import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';


require('rc-checkbox/assets/index.css');



const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 })
  return isDesktop ? children : null
}

const Mobile = ({ children }) => {
  const isDesktop = useMediaQuery({ maxWidth: 767 })
  return isDesktop ? children : null
}


@inject('LoginDetails', 'SiteLinks')
@observer // REMOVE BRACKETS => @observer
class LoginScreen extends Component {

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      userNiceName: '',
      userEmail: '',
      userId: '',
      userRoles: '',
      user_display_name: '',
      loggedIn: false,
      loading: false,
      error: '',
      errorMessage: '',
      CheckboxDisabled: false,
      Termofuse: false,
      child_name: 'Wellcome',
    }
  }

  handleOnChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };


  HandleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.ValidateForm()
    }
  }



  handleTermofuse = (e) => {
    this.setState({ Termofuse: e.target.checked })
    // console.log(this.state.Termofuse);
  }


  ValidateForm = () => {
    // console.log(this.state.Termofuse)


    if (this.state.username === '') {
      this.setState({ errorMessage: <div className="FormError">Enter an email or username</div> });
      this.username.focus();
      return false
    }

    if (this.state.password === '') {
      this.setState({ errorMessage: <div className="FormError">Enter password</div> });
      this.password.focus();
      return false
    }

    if (!this.state.Termofuse) {
      this.setState({ errorMessage: <div className="FormError">you must agree to the terms of use</div> });
      this.Termofuse.focus();
      return false
    }

    if (this.state.error) {
      this.setState({ errorMessage: <div className="FormError">Wrong username or password</div> });
      return false
    }
    else return this.FormSubmit()

  }







  FormSubmit = (event) => {

    // event.preventDefault();

    const siteUrl = clientConfig.siteUrl;

    const loginData = {
      username: this.state.username,
      password: this.state.password,
    };

    this.setState({ loading: true }, () => {

      axios.post(`${siteUrl}/wp-json/jwt-auth/v1/token`, loginData)
        .then(res => {
          console.log(res.data)
          if (undefined === res.data.token) {

            this.setState({ error: res.data.message, loading: false });
            return;
          }
       
          const { token, user_nicename, user_email, user_id, user_roles, user_display_name } = res.data;

          // console.log(res.data)


          const LoginDetails =

          {
            token: token,
            userName: user_nicename,
            userId: user_id,
            userRoles: user_roles,
            userEmail: user_email,
            child_name: this.state.child_name
          }


          localStorage.setItem('login_details', JSON.stringify(LoginDetails));




          localStorage.setItem('token', token);
          localStorage.setItem('userName', user_display_name);
          localStorage.setItem('userId', user_id);
          localStorage.setItem('userRoles', user_roles[0]);
          localStorage.setItem('user_email', user_email);
          localStorage.setItem('child_name', this.state.child_name)






          this.setState({
            loading: false,
            token: token,
            userNiceName: user_nicename,
            userEmail: user_email,
            userId: user_id,
            userRoles: user_roles[0],
            loggedIn: true
          })
        })

        .then(res => {

          const wordPressSiteUrl = clientConfig.siteUrl;
          const AUTH_KEY = clientConfig.AUTH_KEY;

          let data_to_post = {}
          data_to_post.AUTH_KEY = AUTH_KEY
          data_to_post.user_id = this.state.userId
          this.setState({ loading: true });
          axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/user_all_meta_data`, data_to_post, {
            headers: {
              'Content-Type': 'application/json',
            }
          })

            .then(res => {
              localStorage.setItem('user_metadata', JSON.stringify(res.data))

              // console.log(res.data)
              this.setState({ loading: false });

            })
            .catch(err => {
              this.setState({ loading: false });
              console.log('err user_metadata')
            })





        })

        .catch(err => {
          this.setState({ loading: false });
          this.setState({ errorMessage: <div className="FormError">Wrong username or password</div> });
        })
    })



  };



  render() {

    const { username, password, loggedIn, loading, user_display_name } = this.state;

    const user = (user_display_name) ? user_display_name : localStorage.getItem('userName');




    if (loggedIn || localStorage.getItem('token')) {

      const userRoles = localStorage.getItem('userRoles');

      if (userRoles === 'customer') {
        return (<Redirect push to={`/select-program`} />)
      }
      if (userRoles === 'administrator') {
        return (<Redirect push to={`/select-program`} />)
      }

    }

    return (
      <div className={styles.LoginConatiner + ' BackgroundContainer'}>
        <Desktop> <div className={styles.BackgroungImg}><img src={this.props.SiteLinks.LoginBackground} alt=''></img></div> </Desktop>
        <Mobile> <div className={styles.MobileBackgroungImg}><img src={this.props.SiteLinks.LoginBackgroundMobile} alt=''></img></div> </Mobile>

        <div className={styles.FormContainer}>
          <div style={{ width: '100%' }}>
            <div className={styles.LogosContainer}>
              <img className={styles.YeLogo} src={this.props.SiteLinks.young_engineers_logo} alt="young engineers logo"></img>
              <img className={styles.OnlineLogo} src={this.props.SiteLinks.OnlineLogo} alt="young engineers online logo"></img>
            </div>
            <div className="FormTitle">Log in</div>

            {this.state.errorMessage}

            <div className="form_item">
              <input
                placeholder="Username or Email"
                type="text"
                className="form-input"
                name="username"
                value={username}
                onChange={this.handleOnChange}
                onKeyPress={this.HandleKeyPress}
                ref={input => { this.username = input; }}
              />
            </div>

            <div className="form_item with_comment">
              <input
                placeholder="Password"
                type="password"
                className="form-input"
                name="password"
                value={password}
                onChange={this.handleOnChange}
                onKeyPress={this.HandleKeyPress}
                ref={input => { this.password = input; }}
              />
            </div>
            <div className="FlexContainer">
              <div className={styles.InputComment}>
                <div className={styles.ForgotPassword}> <Link to="/PasswordReset">Forgot password?</Link></div>

                <div className={styles.byLogintext}>
                  <Checkbox
                    // defaultChecked
                    onChange={this.handleTermofuse}
                    disabled={this.state.CheckboxDisabled}
                    ref={input => { this.Termofuse = input; }}
                  />

                  <span className={styles.byLogintextSpan}>By logging in I approve the </span><Link className={styles.TermBold} to="/terms-of-use">Terms Of Use</Link></div>
              </div>
            </div>


            <div className="form_item Submitbutton">

              <button className="btn" type="submit" onClick={this.ValidateForm}>Login</button>
            </div>
          </div>
          <div>
            <p className={styles.LoginSmallText}>Want to join Young Engineers Online?</p>

            <p className={styles.LoginSmallText}>
              <Link to="/store">Learn More</Link> <span className={styles.SmallLinksSpace}>|</span> <Link to="/contact-us">Contact Us</Link>
            </p>

          </div>

          {/* <div className={styles.LoginSmallText + ' ' + styles.Contactus}>
            <Link to="/store">Contact Us</Link>
          </div> */}

          {loading && <img className="loader" src={Loader} alt="Loader" />}
        </div>

      </div>

    );


  }
}


export default Radium(LoginScreen);
