import React, { Component, Suspense } from 'react';
import styles from './SinglePruduct.module.css';
import './SinglePruduct.css';
import clientConfig from '../../client-config';

import { isLoggedIn, isStudent, isFranchiseOwner, isAdministrator } from "../../components/functions";
import axios from 'axios';
import Loader from "../../loader.gif";
import LoaderWhite from "../../loaderWhite.gif";

import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import ProductHeader from '../../components/shop/ProductHeader';
import MainInfo from '../../components/shop/MainInfo'
import VideoAndText from '../../components/shop/VideoAndText'
import LogoAndCarousel from '../../components/shop/LogoAndCarousel'
import PlayMachinesSet from '../../components/shop/PlayMachinesSet'
import RawOrderNow from '../../components/shop/RawOrderNow'
import LearningSubjects from '../../components/shop/LearningSubjects'
import Footer from '../../components/landingPage/LandingFooter'

// const ProductHeader = React.lazy(
//     () =>
//         new Promise((resolve, reject) =>
//             setTimeout(() => resolve(import("../../components/shop/ProductHeader")), 1000)
//         )
// );



@inject('woo')
@observer // REMOVE BRACKETS => @observer
class PlayMachines2Upgrade extends Component {


    componentDidMount() {
        this.props.woo.LoadProduct(261)

    }



    render() {
        const TopLogo = this.props.woo.loading

        const wordPressSiteUrl = clientConfig.siteUrl;

        // const LogoWhite = this.props.woo.LogoOnWhite
        const LogoWhite = `${wordPressSiteUrl}/wp-content/uploads/2020/06/plm2_white-1.png`

        // const MainImage = this.props.woo.MainImage
        const MainImage = `${wordPressSiteUrl}/wp-content/uploads/2020/06/PLM2_top.png`

        const Plm2Logo = `${wordPressSiteUrl}/wp-content/uploads/2020/06/PlayMachines2.png`

        const computerImage = `${wordPressSiteUrl}/wp-content/uploads/2020/06/screenPLM2@2x.jpg`
        const kidImage = `${wordPressSiteUrl}/wp-content/uploads/2020/06/KidPLM2@2x.jpg`
        return (


            <>
                {this.props.woo.loading && <img className="loader" src={LoaderWhite} alt="Loader" />}

                <ProductHeader
                    GoBack={() => this.props.history.goBack()}
                    ButtonText='UPGRADE NOW!' loading={this.props.woo.loading} 
                    Title={ReactHtmlParser(this.props.woo.Title)} 
                    Price={this.props.woo.Price} OrderNowLink='https://play.youngengineers.org/shop/checkout/?add-to-cart=261'
                    Shipping={false}
                    />
                <MainInfo
                    loading={TopLogo}
                    Logo={LogoWhite}
                    ShortDescription={ReactHtmlParser(this.props.woo.ShortDescription)}
                    MainImage={MainImage}
                    MarginTopMobileBigImage='70px'
                    MarginBottomMobileBigImage='35px'
                />
                <VideoAndText VideoId='435525755' MainText={ReactHtmlParser(this.props.woo.MainText)} />
                <LogoAndCarousel GalleryImages={this.props.woo.Gallery1} LogoFromLeft={false} LogoSrc={Plm2Logo} />
                <RawOrderNow Price={this.props.woo.Price} ButtonText='UPGRADE NOW!' MarginTop='0px' Image1Src={computerImage} Image2Src={kidImage} LogoAndKitSrc={Plm2Logo} LogoWidth='235px' OrderNowLink='https://play.youngengineers.org/shop/checkout/?add-to-cart=261' />
                <LearningSubjects Text={ReactHtmlParser(this.props.woo.LearningSubjects)} />
                <Footer />
            </>
        );


    }
}

export default withRouter(PlayMachines2Upgrade);


