import React, { Component, Suspense } from 'react';
import styles from './SinglePruduct.module.css';
import './SinglePruduct.css';
import clientConfig from '../../client-config';

import { inject, observer } from 'mobx-react';
import { withRouter, Redirect } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';



@inject('woo')
@observer // REMOVE BRACKETS => @observer
class RedirectTo extends Component {



    render() {


        const search = this.props.location.search; // could be '?foo=bar'
        const params = new URLSearchParams(search);
        const ItemClicked = params.get('item'); // bar



        if (ItemClicked === 'play-machines-1') {
            return (
                <Redirect push to={`/play-machines-1`} />
            )
        }
        if (ItemClicked === 'play-machines-1and2') {
            return (<Redirect push to={`/play-machines-1and2`} />)
        }




        return (
            <div>
                Please wait while you are being redirected.
            </div>
        );


    }
}

export default withRouter(RedirectTo);


