import React, { Component, Suspense } from 'react';
import styles from './SinglePruduct.module.css';
import './SinglePruduct.css';
import clientConfig from '../../client-config';

import { isLoggedIn, isStudent, isFranchiseOwner, isAdministrator } from "../../components/functions";
import axios from 'axios';
import Loader from "../../loader.gif";
import LoaderWhite from "../../loaderWhite.gif";
import { hotjar } from 'react-hotjar';

import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

import ProductHeader from '../../components/shop/ProductHeader';

import MainInfo from '../../components/shop/MainInfo';
import VideoAndText from '../../components/shop/VideoAndText';
import LogoAndCarousel from '../../components/shop/LogoAndCarousel';
import PlayMachinesSet from '../../components/shop/PlayMachinesSet';
import RawOrderNow from '../../components/shop/RawOrderNow';
import LearningSubjects from '../../components/shop/LearningSubjects';
import Footer from '../../components/landingPage/LandingFooter'

// const ProductHeader = React.lazy(
//     () =>
//         new Promise((resolve, reject) =>
//             setTimeout(() => resolve(import("../../components/shop/ProductHeader")), 1000)
//         )
// );



@inject('woo')
@observer // REMOVE BRACKETS => @observer
class PlayMachines1andSet extends Component {
    


    componentDidMount() {
     
        this.props.woo.LoadProduct(99)
        hotjar.initialize('1929700', 6);

    }



    render() {
        console.log(this.props)

        const TopLogo = this.props.woo.loading

        const wordPressSiteUrl = clientConfig.siteUrl;

        // const LogoWhite = this.props.woo.LogoOnWhite
        const LogoWhite = `${wordPressSiteUrl}/wp-content/uploads/2020/05/plm1_and_kit_white.png`

        // const MainImage = this.props.woo.MainImage
        const MainImage = `${wordPressSiteUrl}/wp-content/uploads/2020/06/PLM1_top.png`

        const Plm1Logo = `${wordPressSiteUrl}/wp-content/uploads/2020/06/PlayMachines1.png`
        const PlmSet = `${wordPressSiteUrl}/wp-content/uploads/2020/06/PlmSet@2x.jpg`
        const computerImage = `${wordPressSiteUrl}/wp-content/uploads/2020/06/screenPLM1@2x.jpg`
        const LogoAndKit = `${wordPressSiteUrl}/wp-content/uploads/2020/06/Plm1AndKit@2x-1.png`
        const kidImage = `${wordPressSiteUrl}/wp-content/uploads/2020/06/KidPLM1@2x.jpg`

        return (


            <>
                {this.props.woo.loading && <img className="loader" src={LoaderWhite} alt="Loader" />}

                <ProductHeader
                    GoBack={() => this.props.history.goBack()}
                    ButtonText='ORDER NOW!' loading={this.props.woo.loading}
                    Title={ReactHtmlParser(this.props.woo.Title)}
                    Price={this.props.woo.Price}
                    PriceLine={'95'}
                    OrderNowLink='https://play.youngengineers.org/shop/checkout/?add-to-cart=388'
                    Shipping={true}
                />

                <MainInfo
                    loading={TopLogo}
                    Logo={LogoWhite}
                    ShortDescription={ReactHtmlParser(this.props.woo.ShortDescription)}
                    MainImage={MainImage}
                    MarginTopMobileBigImage='40px'
                    MarginBottomMobileBigImage='35px'
                />
                <VideoAndText VideoId='435525733' MainText={ReactHtmlParser(this.props.woo.MainText)} />
                <LogoAndCarousel GalleryImages={this.props.woo.Gallery1} LogoFromLeft={false} LogoSrc={Plm1Logo} />
                <PlayMachinesSet Text={ReactHtmlParser(this.props.woo.PlayMachinesSet)} ImageSrc={PlmSet} />
                <RawOrderNow PriceLine="$95" Price={this.props.woo.Price} ButtonText='ORDER NOW!' Image1Src={computerImage} Image2Src={kidImage} LogoAndKitSrc={LogoAndKit} OrderNowLink='https://play.youngengineers.org/shop/checkout/?add-to-cart=388' />
                <LearningSubjects Text={ReactHtmlParser(this.props.woo.LearningSubjects)} />
                <Footer />
            </>
        );


    }
}

export default withRouter(PlayMachines1andSet);


