import React, { Component, Suspense } from 'react';
import styles from './LandingPage.module.css';
import { isLoggedIn, isStudent, isFranchiseOwner, isAdministrator } from "../components/functions";
import axios from 'axios';
import Loader from "../loader_loading.gif";

import { hotjar } from 'react-hotjar';

import clientConfig from '../client-config';
import OrderNow from '../components/landingPage/OrderNowButton';
import { withRouter, Link } from 'react-router-dom';
import { Parallax, Background } from 'react-parallax';

import WooCommerceAPI from 'woocommerce-api';
import wooConfig from '../wooConfig';

import { inject, observer } from 'mobx-react';
import { FiPackage } from 'react-icons/fi';


import ShopItem from '../components/landingPage/ShopItem';
import LearningByMaking from '../components/landingPage/LearningByMaking'
import LandingFooter from '../components/landingPage/LandingFooter'




const Products = React.lazy(
    () =>
        new Promise((resolve, reject) =>
            setTimeout(() => resolve(import("../components/landingPage/Products")), 500)
        )
);


const ThreeSimpleSteps = React.lazy(
    () =>
        new Promise((resolve, reject) =>
            setTimeout(() => resolve(import("../components/landingPage/ThreeSimpleSteps")), 2000)
        )
);

const LandingTowItemsRaw = React.lazy(
    () =>
        new Promise((resolve, reject) =>
            setTimeout(() => resolve(import("../components/landingPage/LandingTowItemsRaw")), 2000)
        )
);

const ModelsAnimation = React.lazy(
    () =>
        new Promise((resolve, reject) =>
            setTimeout(() => resolve(import("../components/landingPage/ModelsAnimation")), 4000)
        )
);


const FreeShippingAnimation = React.lazy(
    () =>
        new Promise((resolve, reject) =>
            setTimeout(() => resolve(import("../components/landingPage/FreeShippingAnimation")), 4000)
        )
);


const DistributionMap = React.lazy(
    () =>
        new Promise((resolve, reject) =>
            setTimeout(() => resolve(import("../components/landingPage/DistributionMap")), 4000)
        )
);

const AboutYe = React.lazy(
    () =>
        new Promise((resolve, reject) =>
            setTimeout(() => resolve(import("../components/landingPage/AboutYe")), 4000)
        )
);



const WooCommerce = new WooCommerceAPI({
    url: 'https://play.youngengineers.org/shop/',
    consumerKey: wooConfig.consumerKey,
    consumerSecret: wooConfig.consumerSecret,
    wpAPI: true,
    version: 'wc/v3'
});



@inject('SiteLinks')
@observer // REMOVE BRACKETS => @observer
class LandingPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Data: [],
            ShopData: [],
            IsShopData: false,
            loading: false,
            StoreLoading: false,
        }
    }


    componentDidMount() {
        this.setState({ StoreLoading: true }, () => {
            WooCommerce.getAsync('products').then(res => {
                // console.log(JSON.parse(res.body))
                this.setState({ ShopData: JSON.parse(res.body), IsShopData: true, StoreLoading: false });
                // console.log(this.state.ShopData);
            })
                .catch(err => {

                });
        })



        this.setState({ loading: true }, () => {
            const wordPressSiteUrl = clientConfig.siteUrl;
            const AUTH_KEY = clientConfig.AUTH_KEY;
            let data_to_post = {}
            data_to_post.AUTH_KEY = AUTH_KEY
            data_to_post.PageId = '88'

            axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/get_landing_page_content`, data_to_post, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(res => {
                    console.log(res.data)
                    this.setState({ Data: res.data, loading: false });


                })
                .catch(err => {

                })
        })

        hotjar.initialize('1929700', 6);

    }



    render() {
        const SiteUrl = clientConfig.siteUrl;


        const PLM1_Filter = { ...(this.state.ShopData.filter(x => x.id === 99)) };
        const PLM1_DATA = { ...PLM1_Filter[0] };
        const PLM1_META = Object.values({ ...PLM1_DATA.meta_data });
        const PLM1_Lessons = { ...PLM1_META[9] }
        const PLM1_IMAGES = Object.values({ ...PLM1_DATA.images })

        const PLM1LogoSrc = `${SiteUrl}/wp-content/uploads/2020/07/plm_and_kit@2x.png`
        const PLM1_Gallery = PLM1_IMAGES.filter(x => x.alt === "gallery");

        const PLM1_ShortTextFilter = { ...PLM1_META.filter(x => x.key === "short_text") };
        const PLM1_ShortText = { ...PLM1_ShortTextFilter[0] };


        const PLM2_Filter = { ...(this.state.ShopData.filter(x => x.id === 141)) };
        const PLM2_DATA = { ...PLM2_Filter[0] };
        const PLM2_META = Object.values({ ...PLM2_DATA.meta_data });
        const PLM2_Lessons = { ...PLM2_META[9] }
        const PLM2_IMAGES = Object.values({ ...PLM2_DATA.images })
        const PLM2LogoSrc = `${SiteUrl}/wp-content/uploads/2020/07/plm_and_kit@2x.png`
        const PLM2_Gallery = PLM2_IMAGES.filter(x => x.alt === "gallery");

        const PLM2_ShortTextFilter = { ...PLM2_META.filter(x => x.key === "short_text") };
        const PLM2_ShortText = { ...PLM2_ShortTextFilter[0] };
        // console.log(aaa.value)


        const wordPressSiteUrl = clientConfig.siteUrl;

        const TopImage = `${wordPressSiteUrl}/wp-content/uploads/2020/06/landing_top.jpg`
        const TitleTop = 'Learn, Build, Play!'
        const TopText = 'Discover the world around us by building amazing kinetic models.'



        const LegoBack = `${wordPressSiteUrl}/wp-content/uploads/2020/05/LegoBack.jpg`
        const Multiplatform = `${wordPressSiteUrl}/wp-content/uploads/2020/06/Multiplatform.jpg`

        const BackgroundStyle = {
            backgroundImage: 'url(' + TopImage + ')',

            // //On moble screen
            // '@media (max-width: 850px)': {
            // 	backgroundImage: 'url(' + TopImage + ')',
            // 	backgroundSize: 'contain',
            // 	backgroundRepeat: 'no-repeat',
            // }

        };

        const ShopItems = () => (
            <>
                <ShopItem
                    // Title={'Price: $' + PLM1_DATA.price + ' + Shipping'}
                    Title={PLM1_Lessons.value}
                    ReadMore='/play-machines-1'
                    // ReadMore='/store-click-redirect?item=play-machines-1'
                    Logo={PLM1LogoSrc}
                    Lessons={PLM1_Lessons.value}
                    ShortText={PLM1_ShortText.value}
                    GalleryImages={PLM1_Gallery}
                    BuyNowLink='/play-machines-1' />

                <ShopItem
                    // Title={'Price: $' + PLM2_DATA.price + ' + Shipping'}
                    Title={PLM2_Lessons.value}
                    ReadMore='/play-machines-1and2'
                    // ReadMore='/store-click-redirect?item=play-machines-1and2'
                    Logo={PLM2LogoSrc}
                    Lessons={PLM2_Lessons.value}
                    ShortText={PLM2_ShortText.value}
                    GalleryImages={PLM2_Gallery}
                // BuyNowLink='/play-machines-1and2'

                // BuyNowLink='https://play.youngengineers.org/shop/checkout/?add-to-cart=141'
                />
            </>
        );


        // const LinkToPackges = <div className={styles.ViewPackgesBt}> <Link to='/packages-direct' className={styles.ViewPackges}>VIEW PACKAGES {<FiPackage className={styles.FiPackage} />}</Link></div>
        const LinkToPackges = <div className={styles.ViewPackgesBt}> <Link to='/packages' className={styles.ViewPackges}>VIEW PACKAGES {<FiPackage className={styles.FiPackage} />}</Link></div>

        return (


            <React.Fragment>



                {/* {this.state.loading && <img className="loader" src={Loader} alt="Loader" />} */}
                <div className='PageConatiner'>

                    <div className="FlexContainer">
                        <div className={styles.ItemsTopImage} style={BackgroundStyle}>
                            <div className={styles.TopLeftItem}>
                                <img src={`${wordPressSiteUrl}/wp-content/uploads/2020/05/ye_white_logo.png`} />
                                <div className={styles.TitleTop + ' ' + styles.TopPadding}>{TitleTop}</div>
                                <div className={styles.TopText + ' ' + styles.TopPadding}>{TopText}</div>
                                <div className={styles.topbt}> {LinkToPackges}</div>

                                {/* <OrderNow ButtonText='ORDER NOW!' MarginTop='2%' MarginLeft='10%' Position='left' LinkTo='#products' /> */}

                                <div className={styles.Mobile + ' ' + styles.RecogbizedImg}><img src={`${wordPressSiteUrl}/wp-content/uploads/2020/05/recognized_by.png`} /></div>
                            </div>
                            <div className={styles.TopRightItem}>
                                <img src={`${wordPressSiteUrl}/wp-content/uploads/2020/05/recognized_by.png`} />
                            </div>
                        </div>


                    </div>
                    <Suspense fallback={null} >
                        <LearningByMaking Img1={this.props.SiteLinks.Swing} Img2={this.props.SiteLinks.WormRobot} />
                        <div className={styles.Freeshippingcontainer}>
                            <FreeShippingAnimation />
                            <div className={styles.ViewPackgesContainer}>
                                {/* <img src={this.props.SiteLinks.kitAndTablet} /> */}
                                {LinkToPackges}

                            </div>
                            <div className={styles.freeShipping}>free shipping</div>
                        </div>
                        {/* <Products LegoBack={LegoBack} StoreLoading={this.state.StoreLoading} IsShopData={this.state.IsShopData} Loader={Loader}>
                            <ShopItems />
                        </Products> */}
                    </Suspense>



                    <Suspense fallback={null} >
                        {/* fallback={<img className={"loader " + styles.StoreLoader} src={Loader} alt="Loader" />} */}
                        <ThreeSimpleSteps />
                        <LandingTowItemsRaw VideoId='435468526' ImageSrc={Multiplatform} />
                        <div className={styles.bottombt}> {LinkToPackges}</div>
                        {/* <OrderNow ButtonText='ORDER NOW!' MarginLeft='0' Position='center' LinkTo='#products' /> */}
                    </Suspense>

                    <Suspense fallback={null} >
                        <ModelsAnimation />
                        <DistributionMap />
                        <AboutYe />
                        <LandingFooter />
                    </Suspense>

                </div>
            </React.Fragment>
        );


    }
}

export default withRouter(LandingPage);
