import React, { Component } from 'react';
import axios from 'axios';
import clientConfig from '../../client-config';
import styles from '../../components/Customer/Form.module.css';

import "react-datepicker/dist/react-datepicker.css";

import Loader from "../../loader.gif";

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';



@inject('LoginDetails', 'SiteLinks')
@observer // REMOVE BRACKETS => @observer
class FromChildDetails extends Component {


    constructor(props) {
        super(props);

        this.state = {
            ChildFirstName: '',
            ChildAge: '',
            loading: false,
            error: '',
            errorMessage: '',
        }
    }



    ValidateForm = (event) => {
        event.preventDefault();
        const { ChildFirstName, ChildAge } = this.state;

        this.setState({ errorMessage: '' });

        if (ChildFirstName === '') {
            this.setState({ errorMessage: <div className={styles.FormErr + " FormError"}>Enter Child First Name</div> });
            return false;
        }

        if (ChildAge === '') {
            this.setState({ errorMessage: <div className={styles.FormErr + " FormError"}>Enter Child Age</div> });
            return false;
        }
        this.handleFormSubmit()

    };


    handleOnChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };





    handleFormSubmit = () => {
        // event.preventDefault();

        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        const UserId = localStorage.getItem('userId')

        const { ChildFirstName, ChildAge, loading } = this.state;

        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.UserId = UserId
        data_to_post.ChildFirstName = ChildFirstName
        data_to_post.ChildAge = ChildAge


        this.setState({ loading: true }, () => {

            axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/UpdateChildDetails`, data_to_post, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(res => {
                    // console.log(res.data)
                    this.setState({ HaveChildName: true });
                    localStorage.setItem('child_name', ChildFirstName);
                    window.location.reload(false);
                })

                .catch(err => {
                    this.setState({ loading: false });
                })
        })

    };

    render() {


        const { ChildFirstName, ChildAge, loading } = this.state;


        return (
            <div className={styles.FormChildDetails}>
                {loading && <img className="loader" src={Loader} alt="Loader" />}
              
              
                <form className={styles.ChildDetailsFormContainer} onSubmit={this.ValidateForm}>
                        <div style={{ width: '100%' }}>

                            <div className={styles.FormTitle}>Wellcome To <br />Young Engineers Online</div>

                            <div className={styles.FormSubTitle}>Enter Your Child's Detail:</div>

                            {this.state.errorMessage}

                            <div className={styles.form_FlexContainer}>

                                <div className={styles.ItemsFlexContainer}>

                                    <div className={styles.form_item + ' ' + styles.FirstName}>
                                        <input
                                            placeholder="Child First Name"
                                            type="text"
                                            className={styles.FromInput}
                                            name="ChildFirstName"
                                            value={ChildFirstName}
                                            onChange={this.handleOnChange}
                                        />
                                    </div>


                                    <div className={styles.form_item + ' ' + styles.Age}>
                                        <input
                                            placeholder="Age"
                                            type="number"
                                            className={styles.FromInput}
                                            name="ChildAge"
                                            value={ChildAge}
                                            onChange={this.handleOnChange}
                                        />

                                    </div>


                                </div>


                            </div>


                            <div className={styles.Submitbutton} >
                                <button className={styles.Btn + ' ' + styles.GreenBtn} type="submit">Begin Learning</button>
                            </div>
                        </div>




                    </form>
            </div>
        );


    }
}

export default FromChildDetails;
