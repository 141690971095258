import React from 'react';
import styles from './VideoAndText.module.css';
import '../../screens/LandingPage.css';
import YouTube from 'react-youtube-embed'
import Vimeo from '@u-wave/react-vimeo';

import { Parallax, Background } from 'react-parallax';

class SingleVideoOnMobile extends React.Component {


    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }

    openModal() {
        this.setState({ isOpen: true })
    }

    render() {


        const ImageSrc = this.props.ImageSrc


        return (
            <>

                <div className={styles.FlexItemTowItemsRaw}>

                    <div className={styles.VideoTowItems + ' ' + styles.Plm2VideoFormMobile} >
                        <Vimeo video={this.props.VideoId2} autoplay={false} />
                    </div>

                </div>
            </>
        );
    }
}

export default SingleVideoOnMobile;