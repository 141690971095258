import React from 'react';
import styles from './LearningSubjects.module.css';

import ReactHtmlParser, { htmlparser2 } from 'react-html-parser';

class LearningSubjects extends React.Component {




    render() {


        return (

            <div className={styles.Container} >

               <div className={styles.TextContainer}>{this.props.Text}</div> 
            </div>


        );
    }
}

export default LearningSubjects;