import React, { Component } from 'react';
import axios from 'axios';
import clientConfig from '../../client-config';
import styles from '../../components/Customer/ContactUsForm.module.css';

import { isLoggedIn } from "../../components/functions";

import { Redirect } from "react-router-dom";

import * as EmailValidator from 'email-validator';
import DatePicker from "react-datepicker";
import { getUserName } from "../functions";
import "react-datepicker/dist/react-datepicker.css";

import Loader from "../../loader.gif";

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

import { IoMdThumbsUp } from "react-icons/io";



@inject('LoginDetails', 'SiteLinks')
@observer // REMOVE BRACKETS => @observer
class FromChildDetails extends Component {


    constructor(props) {
        super(props);

        this.state = {
            From: '',
            Email: '',
            Message: '',
            EmailSent: false,
            loading: false,
            error: '',
            errorMessage: '',
        }
    }

    StatesSet = () => {
        const FirstName = toJS(this.props.LoginDetails.UserMeta.first_name[0])
        const Email = toJS(this.props.LoginDetails.LoginDetails.userEmail)
        if (FirstName !== null) {
            this.setState({ From: FirstName });
        }
        if (Email !== null) {
            this.setState({ Email: Email });
        }

    }

    componentDidMount() {
        isLoggedIn() ? (
            this.StatesSet()

        ) :
            null


    }
    ValidateForm = (event) => {
        event.preventDefault();
        const { From, Email, Message } = this.state;

        this.setState({ errorMessage: '' });

        if (From === '') {
            this.setState({ errorMessage: <div className={styles.FormErr + " FormError"}>Enter Name</div> });
            return false;
        }

        if (Email === '') {
            this.setState({ errorMessage: <div className={styles.FormErr + " FormError"}>Enter Email</div> });
            return false;
        }

        if (!EmailValidator.validate(Email)) {
            this.setState({ errorMessage: <div className={styles.FormErr + " FormError"}>Enter Valid Email</div> });
            return false;
        }

        if (Message === '') {
            this.setState({ errorMessage: <div className={styles.FormErr + " FormError"}>Write Message</div> });
            return false;
        }


        this.handleFormSubmit()

    };


    handleOnChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };





    handleFormSubmit = () => {
        // event.preventDefault();

        const wordPressSiteUrl = clientConfig.siteUrl;
        const AUTH_KEY = clientConfig.AUTH_KEY;
        const UserId = localStorage.getItem('userId')

        const { From, Email, Message } = this.state;

        let data_to_post = {}
        data_to_post.AUTH_KEY = AUTH_KEY
        data_to_post.UserId = UserId
        data_to_post.From = From
        data_to_post.Email = Email
        data_to_post.Message = Message

        this.setState({ loading: true }, () => {

            axios.post(`${wordPressSiteUrl}/wp-json/wl/v1/ContactUsEmail`, data_to_post, {
                headers: {
                    'Content-Type': 'application/json',
                }
            })
                .then(res => {
                    this.setState({ EmailSent: true, loading: false });
                })

                .catch(err => {
                    this.setState({ loading: false });
                    console.log('ContactUsEmail ERR')
                })
        })

    };

    render() {


        const { From, Email, Message, loading } = this.state;



        // console.log(toJS(this.props.LoginDetails.UserMeta.first_name[0]))




        if (!this.state.EmailSent) {

            return (
                <div className={styles.FormChildDetails}>
                    {loading && <img className="loader" src={Loader} alt="Loader" />}

                    <form className={styles.ChildDetailsFormContainer} onSubmit={this.ValidateForm}>
                        <div style={{ width: '100%' }}>

                            <div className={styles.FormTitle}>Tell us something</div>


                            {this.state.errorMessage}

                            <div className={styles.form_FlexContainer}>

                                <div className={styles.form_item}>
                                    <div className={styles.form_itemTitle}>From</div>
                                    <input
                                        placeholder="from"
                                        type="text"
                                        className={styles.FromInput}
                                        name="From"
                                        value={From}
                                        onChange={this.handleOnChange}
                                    />
                                </div>


                                <div className={styles.form_item}>
                                    <div className={styles.form_itemTitle}>Email</div>
                                    <input
                                        placeholder="Email"
                                        type="text"
                                        className={styles.FromInput}
                                        name="Email"
                                        value={Email}
                                        onChange={this.handleOnChange}
                                    />

                                </div>

                                <div className={styles.form_item}>

                                    <textarea
                                        placeholder="Your Message"
                                        type="textarea"
                                        className={styles.Textarea}
                                        name="Message"
                                        value={Message}
                                        onChange={this.handleOnChange}
                                    >
                                    </textarea>

                                </div>

                            </div>
                            <div className={styles.Submitbutton} >
                                <button className={styles.Btn + ' ' + styles.BlueBtn} type="submit">SEND</button>
                            </div>
                        </div>

                    </form>
                </div>
            );


        }
        else {
            return (
                <div className={styles.FormChildDetails}>
                    {loading && <img className="loader" src={Loader} alt="Loader" />}

                    <div className={styles.EmailSentFormContainer} onSubmit={this.ValidateForm}>
                        <div style={{ width: '100%' }}>
                            <IoMdThumbsUp size={40} color="#2E9627" className={styles.IoMdThumbsUp} />
                            <div className={styles.emailSent}>
                                Your message has been sent <br />
                        our team will respond shortly
                    </div>






                        </div>

                    </div>
                </div>
            );
        }





    }
}

export default FromChildDetails;
