import { observable, configure, runInAction, action, toJS } from 'mobx';
import parse from 'html-react-parser'
import axios from 'axios';
import clientConfig from '../client-config';

import WooCommerceAPI from 'woocommerce-api';
import wooConfig from '../wooConfig';

configure({ enforceActions: true });


class Programs {
    @observable loading = false
    @observable Plm1 = []
    @observable Plm2 = []
    @observable Lesson = []
    @observable Plm1Logo = []
    @observable FatchEnd = false
    @observable ShowUpgrade = false
    @observable HideClicked = localStorage.getItem('HideClicked')




    @action IsHideClicked() {
        const Hide = localStorage.getItem('HideClicked')
        return Hide
    }


    @action ShowUpgradeTrue() {
        this.ShowUpgrade = true;

    }

    @action ShowUpgradeFalse() {
        this.ShowUpgrade = false;

    }


    LoadPrograms() {
        const wordPressSiteUrl = clientConfig.siteUrl;

        this.loading = true
        axios.get(`${wordPressSiteUrl}/wp-json/wp/v2/pages?parent=635`)
            .then(res => {

                const AllPages = res.data
                const Plm1 = res.data.filter(page => page.title.rendered === "play-machines-1");
                const Plm2 = res.data.filter(page => page.title.rendered === "play-machines-2");
                // SORT EXZAPLE
                // this.Programs = Programs.sort((a, b) => (a.metadata.program_name > b.metadata.program_name) ? 1 : -1)

                // console.log(Plm1)
                runInAction(() => {
                    this.loading = false
                    this.FatchEnd = true
                    this.Plm1 = Plm1
                    this.Plm2 = Plm2

                });
            })
            .catch(err => {
                runInAction(() => {
                    this.loading = false
                    console.log('LoadPrograms ERR')
                });
            })

    }



    constructor() {
        this.LoadPrograms()


    };



};







export default new Programs();





