import React from 'react';
import styles from './NavbarCustomer.module.css';
import { getChildName, IsCustomer, isLoggedIn } from "../functions";
import { slide as Menu } from 'react-burger-menu'
import NavLink from '../NavLink';
import { withRouter, Link, Redirect } from "react-router-dom";

import { PopupMenu } from 'react-rectangle-popup-menu';
import { GoGear } from "react-icons/go";
import { RiAccountCircleLine, RiStoreLine } from "react-icons/ri"
import { AiOutlineMail } from "react-icons/ai";
import { FiGlobe, FiHome } from "react-icons/fi";

import './Navbar.css'
import Radium from 'radium';

import PopUpLink from '../Navbars/PopUpLink'
import PopUpLinkHref from '../Navbars/PopUpLinkHref'

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';


@inject('LoginDetails', 'SiteLinks')
@observer // REMOVE BRACKETS => @observer
class NavbarCustomer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ManuIsOpen: false,
        }
    }


    handleLogout = () => {
        localStorage.clear();
        window.location.href = '/';
    };

    test = () => {
        console.log("test")
    };

    render() {

        // console.log(toJS(this.props.LoginDetails.LoginDetails.userId))
        // const userName = (getUserName()) ? getUserName() : '';

        const ChildName = (getChildName()) ? getChildName() : '';
        // const ChildName = this.props.LoginDetails.ChildName ? this.props.LoginDetails.ChildName : '' ;

        // console.log(getChildName())
        const BackgroundStyle = {
            backgroundImage: 'url(' + this.props.SiteLinks.CustomerHeader + ')',



        };

        let Name = null
        if (ChildName !== '') {
            Name = (
                <NavLink to={`/`}>
                    <div className={styles.StudentName}>
                        Hi {ChildName}
                    </div>
                </NavLink>
            )
        }

        let KitFirstClick = ''
        if (IsCustomer()) {
            KitFirstClick = 'KitOnHeaderFirstClick'
            if (this.props.LoginDetails.UserMeta) {

                const KitFirstClickValue = toJS(this.props.LoginDetails.UserMeta.kit_on_header_clicked[0])

                if (KitFirstClickValue == 1) {
                    KitFirstClick = ''
                }
            }

        }

        return (
            <>

                <Menu customBurgerIcon={<GoGear className={styles.GearIcon} />} right >
                    <PopUpLink
                        LinkTo="/"
                        Icon={<FiHome />} Left="0px" FontSize="30px" Top='5px' Text="Choose Program" MarginTop="35px" />
                    <PopUpLink
                        LinkTo="/user-profile"
                        Icon={<RiAccountCircleLine />} Left="0px" FontSize="30px" Top='5px' Text="My Profile" />
                    <PopUpLink
                        LinkTo="/store"
                        Icon={<RiStoreLine />} Left="0px" FontSize="30px" Top='4px' Text="Store" />
                    <PopUpLink
                        LinkTo="/contact-us"
                        Icon={<AiOutlineMail />} Left="0px" FontSize="30px" Top='4px' Text="Contac Us" />
                    <PopUpLinkHref
                        LinkTo="https://youngengineers.org/"
                        Icon={<FiGlobe />} Left="0px" FontSize="30px" Top='4px' Text="Main Site" />

                    <div className={styles.PopUpLogOutContainer}>
                        <div className={styles.PopUpLogOut} onClick={this.handleLogout} >Log out</div>
                    </div>

                    <div className={styles.PopUpTermsContainer}>
                        <NavLink className={styles.PopUpLink} to={`/terms-of-use`}>
                            <div className={styles.PopUpTerms}>Terms of use</div>
                        </NavLink>
                    </div>
                </Menu>


                <div className={styles.NavigationContainer} >

                    <div style={BackgroundStyle} className={styles.NavBar} >

                       
                    </div>
                </div>
                <div className={styles.CenterItemOutOfHeader}>
                    <div className={styles.HeaderLinks}>
                        <Link to={"/"} className={styles.StudentGoBackOutOfHeader}>{this.props.Home}</Link>
                        <div onClick={() => this.props.history.goBack()} className={styles.StudentGoBackOutOfHeader}>{this.props.GoBack}</div>
                    </div>
                    <div className={styles.StudentTitleOutOfHeader}>{this.props.Title}</div>
                </div>
            </>
        );
    }
}

export default withRouter(Radium(NavbarCustomer));


