
import React, { useState, useEffect } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Radium from 'radium';
import styles from './ProductHeader.module.css';
import { Link } from 'react-router-dom';

import OrderNow from '../../components/landingPage/OrderNowButton';
import ProductBreadcrumbs from '../../components/shop/ProductBreadcrumbs';


const UpdateHeaderHeight = (props) => {

    const [isScrolling, setIsScrolling] = useState(false);
    const [HeightLeve1, setHeightLeve1] = useState('120px');
    const [HeightLeve2, setHeightLeve2] = useState('160px');
    const [HeightLeve3, setHeightLeve3] = useState('220px');
    const [Display, setDisplay] = useState('flex');



    useScrollPosition(({ prevPos, currPos }) => {
        if (currPos.y < -10) {
            setIsScrolling(true)
            setHeightLeve1('90px')
            setHeightLeve2('90px')
            setHeightLeve3('120px')
            setDisplay('none')
            console.log(isScrolling)
        }
        else {
            setIsScrolling(false)
            setHeightLeve1('130px')
            setHeightLeve2('150px')
            setHeightLeve3('220px')
            setDisplay('flex')
            console.log(isScrolling)
        }
    })

    const HeaderHeight = {
        height: HeightLeve1,
        transition: '0.5s',
        //On moble screen

        '@media (max-width:1200px) and (min-width:851px)': {
            height: HeightLeve2,
            transition: '0.5s'
        },

        '@media (max-width:850px)': {
            height: HeightLeve3,
            transition: '0.5s'
        }

    };


    const StyleTitleAndPrice = {

        //On moble screen

        '@media (max-width:1200px) and (min-width:851px)': {
            display: Display,
            transition: '0.5s'
        },

        '@media (max-width:850px)': {
            display: Display,
            transition: '0.5s'
        }

    };

    const IsShipping = props.Shipping
    const Shipping = IsShipping ? 'FREE SHIPPING' : ''

    return (

        <div style={HeaderHeight} className={styles.HeaderContainer}>
            <div style={HeaderHeight} className={styles.Header}>
                {props.loading ? <div></div> :
                    <div style={StyleTitleAndPrice} className={styles.TitleAndPrice}>
                        <Link to='/store' className={styles.GoBack}>{"<< "}Go Back</Link>
                        {/* <div onClick={props.GoBack} className={styles.GoBack}>{"<< "}Go Back</div> */}
                        {props.Title} <span className="divider">|</span>

                        <div className={styles.FreeShippingAndPrice}>
                            <span className='price' style={{ marginRight: '10px' }}> {'$' + props.Price}</span>
                            <span className={styles.priceline + ' price_line price'}>{'$' + props.PriceLine}</span>
                            <span className={styles.pricespaceright + ' price'}>,</span>
                            <span className='price'>{Shipping}</span>
                        </div>
                    </div>}
                <OrderNow ButtonText={props.ButtonText} LinkTo={props.OrderNowLink} />
            </div>

        </div>

    );
}




export default Radium(UpdateHeaderHeight);