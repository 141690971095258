import React, { Component } from 'react';

import styles from '../Customer/Lesson.module.css';
import { withRouter, Redirect } from 'react-router-dom';

import { isLoggedIn } from "../../components/functions";

import Navbarcustomer from "../../components/Navbars/NavbarCustomer";
import { FaRegArrowAltCircleLeft } from 'react-icons/fa';
import { FiHome } from "react-icons/fi";

import { useMediaQuery } from 'react-responsive';

import Loader from "../../loader.gif";

import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { RiGalleryLine } from 'react-icons/ri';

import ImageGallery from 'react-image-gallery';
import Vimeo from '@u-wave/react-vimeo';

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 801 })
    return isDesktop ? children : null
}

const Mobile = ({ children }) => {
    const isDesktop = useMediaQuery({ maxWidth: 800 })
    return isDesktop ? children : null
}



@inject('LoginDetails', 'SiteLinks', 'Programs', 'Lessons')
@observer // REMOVE BRACKETS => @observer
class Lesson extends Component {


    componentDidMount() {
        // console.log(this.props.match.params.programName)
        this.props.Lessons.UpdateLessonData(this.props.match.params.LessonsId)

        const LessonsId = this.props.match.params.LessonsId
        if (LessonsId == 793) {
            this.props.LoginDetails.UpdateMetaItem('kit_on_header_clicked', 1)
            this.props.LoginDetails.UpdateUserMeta()
        }
    }



    resetVideo() {
        this.props.Lessons.showVideo = {}

        if (this.props.Lessons.showPlayButton) {
            this.props.Lessons.showGalleryPlayButton = true
        }

        if (this.props.Lessons.showFullscreenButton) {
            this.props.Lessons.showGalleryFullscreenButton = true
        }
    }



    SwitchDesktopBack(param) {
        if (this.props.Programs.FatchEnd) {
            switch (param) {
                case 'play-machines-1':
                    return toJS(this.props.Programs.Plm1[0].program_desktop_backgroung[0]);
                case 'play-machines-2':
                    return toJS(this.props.Programs.Plm2[0].program_desktop_backgroung[0]);
                default:
                    return toJS(this.props.SiteLinks.WhiteBackground);
            }
        }
    };


    SwitchMobileBack(param) {
        if (this.props.Programs.FatchEnd) {
            switch (param) {
                case 'play-machines-1':
                    return toJS(this.props.Programs.Plm1[0].program_mobile_backgroung[0]);
                case 'play-machines-2':
                    return toJS(this.props.Programs.Plm2[0].program_mobile_backgroung[0]);
                default:
                    return toJS(this.props.SiteLinks.WhiteBackgroundMobile);
            }
        }


    };



    render() {

        const ProgramName = this.props.match.params.programName

        let Header = <Navbarcustomer Title={this.props.Lessons.LessonTitle} GoBack={<FaRegArrowAltCircleLeft />} Home={<FiHome />} />


        const LessonParentProgram = toJS(this.props.Lessons.LessonParentProgram)

        if (LessonParentProgram === 'play-machines-1') {
            if (!this.props.LoginDetails.HavePlm1()) {
                return <Redirect push to={`/select-program`} />
            }
        }

        if (LessonParentProgram === 'play-machines-2') {
            if (!this.props.LoginDetails.HavePlm2()) {
                return <Redirect push to={`/select-program`} />
            }
        }

        return (

            !isLoggedIn() ? (

                window.location.href = '/'

            ) :

                <React.Fragment>

                  

                    <div className='PageConatiner'>
                    {Header}

                        {this.props.Lessons.loading && <img className="loader" src={Loader} alt="Loader" />}

                        <Desktop> <div className={styles.BackgroungImg}><img src={this.SwitchDesktopBack(ProgramName)} alt="young engineers logo"></img></div> </Desktop>
                        <Mobile> <div className={styles.MobileBackgroungImg}><img src={this.SwitchMobileBack(ProgramName)} alt="young engineers logo"></img></div> </Mobile>

                        <div className={styles.ImageGalleryContainer}>

                            <ImageGallery
                                ref={i => this._imageGallery = i}
                                // onClick={this.props.Lessons._onImageClick.bind(this)}
                                onSlide={this.props.Lessons.onSlide.bind(this)}
                                showFullscreenButton={this.props.Lessons.showGalleryFullscreenButton}
                                showPlayButton={this.props.Lessons.showPlayButton}
                                items={this.props.Lessons.LessonData}
                                infinite={false}
                                useBrowserFullscreen={false}
                            // onThumbnailClick={}
                            />
                        </div>

                    </div>
                </React.Fragment>
        );


    }
}

export default withRouter(Lesson);
